import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_SCALE_5_COLORS, PRODUTURO_PIE_COLORS, PRODUTURO_PIE_SINO_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoNpsAcumulado, getProfuturoNpsPorMes,getProfuturoNpsAcumuladoByBimonthly, getExtrasFechas, getMapperBimestralHistorico } from "../commonWidgetsProfuturo";


export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },

        getProfuturoNpsAcumuladoByBimonthly({
            size: [1, 2],
            indicator: 'cuest_sofom_ley_pregunta_2',
            extras: getExtrasFechas
        }),

        getProfuturoNpsPorMes({
            helpText: 'A partir de tu experiencia, en una escala del 0 al 10, dónde 0 es nada probable y 10 muy probable, ¿qué tan probable es que nos recomiendes con un amigo o familiar?',
            size: [1, 2],
            indicator: 'cuest_sofom_ley_pregunta_2',
            extras: getExtrasFechas,
            historicGrouped: 'formattedBimonthlyPeriod',
            historicLabel: 'group',
            historicExtras: getExtrasFechas,
            historicMapper: getMapperBimestralHistorico
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Medio de preferencia',
            size: [2, 2],
            helpText: '¿Por qué medio te gustaría recibir información sobre tu préstamo?',
            indicator: 'cuest_sofom_ley_pregunta_25',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Recomendacion',
            size: [2, 2],
            helpText: 'En el último año, ¿recomendaste contratar un préstamo en Profuturo a otras personas?',
            indicator: 'cuest_sofom_ley_pregunta_19',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Cantidad de personas recomendadas',
            size: [2, 2],
            helpText: '¿A cuántas personas le recomendaste contratar un préstamo en Profuturo?',
            indicator: 'cuest_sofom_ley_pregunta_20',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Recontratacion de préstamo',
            size: [2, 2],
            helpText: 'Si tu experiencia con Profuturo se mantiene igual que hasta ahora, ¿estarías dispuesto a volver a contratar un préstamo con nosotros?',
            indicator: 'cuest_sofom_ley_pregunta_21',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Motivo por el cual vovería a solicitar un préstamo',
            size: [2, 2],
            helpText: '¡Nos alegra escucharlo! ¿Nos podrías contar por qué?',
            indicator: 'cuest_sofom_ley_pregunta_23',
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Motivo por el que no aceptaría un préstamo',
            size: [2, 2],
            helpText: '¿Por qué razón NO estarías dispuesto a volver a contratar un préstamo con nosotros nuevamente?',
            indicator: 'cuest_sofom_ley_pregunta_22',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        {
            title: 'Atributos en la experiencia de Grado de SATISFACION',
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La facilidad para comunicarse con Profuturo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_12',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La rapidez y facilidad en los procesos / trámites',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_13',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La buena atención por parte del personal',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_14',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El cumplimiento con lo que me ofrecieron al momento de la contratación',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_15',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La recepción de mi Estado de Cuenta de manera puntual (mensualmente) Clientes con Préstamo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_16',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La comunicación recibida por parte de Profuturo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_17',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La frecuencia con la que me contactan / mandan información',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_sofom_ley_pregunta_18',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            size: [4, 4],
            indicator: 'SENTIMENT',
            extras: getExtrasFechas,
        }),
    ]
} as DasboardConfig
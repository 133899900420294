import { getClientConfigs, } from './clientConfigs'

import logoClientship from '@assets/images/logo-clientship.png'
import { ActionPlansConfig, OmoikaneConfig } from './omoikane/omoikaneCommons'
import { ClientDashboardConfig, DASHBOARDS, DashboardConfigInfo, DashboardList } from './dashboard/dashboardCommons'
import { AlertDetailField, FILTER_TYPES, HelpDefinition, METADADATA_LOAD, NotificationsConfig, RatingRanges, SurveyResponsesTab, SuveyResponsesIndicatorsConfig, QuestionOrder, AlertHeaderField, SurveyResponseStats, AlertStats  } from './configs/configCommons'
import { ColumnsType } from 'antd/lib/table'
import { SurveysCountInfo } from '@modules/AppDataModule'

interface ClientConfig {
  // General
  getClientName: () => string
  getVocBackendUrl: () => string
  getLcmBackendUrl: () => string
  getCaseBackendUrl: () => string
  getClientLogo: () => any
  getClientLogoSmall: () => any
  getClientshipLogo: () => any
  getPublicUrl: () => string | undefined
  supportsInternationalization: () => boolean
  getDecimals: () => number | undefined
  getRatingRanges: () => RatingRanges
  getHelpDefinitions: () => HelpDefinition

  // Filters
  includesFilterDate: (userProfiles: UserProfile[]) => boolean
  includesFilterGeo: (userProfiles: UserProfile[]) => boolean
  includesFilterLogical: (userProfiles: UserProfile[]) => boolean
  includesFilterCriticalMoment: (userProfiles: UserProfile[]) => boolean
  includesFilterCluster: (userProfiles: UserProfile[]) => boolean
  includesFilterChannel: (userProfiles: UserProfile[]) => boolean
  includesFilterDynamic: (userProfiles: UserProfile[]) => boolean
  useCriticalMoments: () => boolean
  useChannels: () => boolean
  useLogicalLocations: () => boolean
  useGeoLocations: () => boolean
  useClusters: () => boolean
  useWorkflows: () => boolean
  getGeosRootId: () => number
  getLogicalsRootId: () => number
  getFilterFromDateSubstract: () => number
  getFilterToDateSubstract: () => number
  getDateFromParamName: () => string
  getDateToParamName: () => string
  getCriticalMomentsRootId: () => number
  getCriticalMomentFilterType: () => 'tree' | 'select'
  useCriticalMomentMultipleSelect: () => boolean,
  getFilterCriticalMoments: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => CriticalMoment[]
  getFilterCriticalMomentDefault: (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => number

  // Dashboard
  getClientMenuDashboards: () => DASHBOARDS[]
  getDashboards: () => ClientDashboardConfig
  useBackDashboards: () => boolean
  getStaticDashboards: () => DashboardList
  getDynamicDashboards: () => number[]
  getDashboardConfig: (dashboard: string) => DashboardConfigInfo
  getSentimentCommentsIndicatorName: () => string
  getUseDefaultCriticalMoment: () => boolean
  useDashboardDynamicPosition: () => boolean

  // Reports
  useDynamicReports: () => boolean
  getMsExcelReportExt: () => string

  // Omoikane
  getOmoikaneConfig: () => OmoikaneConfig

  // Action Plan 
  getActionPlansConfig: () => ActionPlansConfig

  // Survey Responses
  getSurveyResponsesUrl: () => string
  getSurveyResponsesTableColumns: () => ColumnsType<any> | undefined
  getSurveyResponsesTabs: () => SurveyResponsesTab[]
  getSurveyResponsesType: () => string
  getSurveyResponsesIndicators: () => SuveyResponsesIndicatorsConfig | undefined
  getSurveysCountersValue: (countersInfo: SurveysCountInfo) => number

  // Alerts
  getAlertDetailFields: () => AlertDetailField[]
  getAlertHeaderFields: () => AlertHeaderField[],
  useAlertWorkflows: () => boolean

  // Notifications
  getNotificationsConfig: () => NotificationsConfig

  // Customer data info
  useUserPhoneNumber: () => boolean
  getPageSize: () => number
  getQuestionsCustomerName: () => String[]

  getQuestionsOrder: () => QuestionOrder[]
  
  //Survey stats
  getSurveyResponseStats: () => SurveyResponseStats
  
  //Alert Stats
  getAlertStats: () => AlertStats | undefined
   
}

export default function useClientConfig(): ClientConfig {
  const getClientName = (): string => {
    const nodeEnvironment: string | undefined = process.env.NODE_ENV
    //console.log('CLIENTE: ' + nodeEnvironment)

    if (nodeEnvironment === undefined) {
      throw new Error('Cliente no definido!!!')
    }

    if (nodeEnvironment === 'benavides') {
      return 'banregio'
    }

    return nodeEnvironment
  }

  const getVocBackendUrl = (): string => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.vocBackendUrl
  }

  const getLcmBackendUrl = (): string => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.lcmBackendUrl
  }

  const getCaseBackendUrl = (): string => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.caseBackendUrl ?? ''
  }

  const getPublicUrl = (): string | undefined => {
    const clientName = getClientName()
    return getClientConfigs(clientName).publicUrl
  }

  const getDecimals = (): number | undefined => {
    const clientName = getClientName()
    return getClientConfigs(clientName).decimals
  }

  const getDashboards = (): ClientDashboardConfig => {
    const clientName = getClientName()
    return getClientConfigs(clientName).dashboards
  }

  const getStaticDashboards = (): DashboardList => {
    const clientName = getClientName()
    return getClientConfigs(clientName).staticDashboards
  }

  const getDynamicDashboards = (): number[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).dynamicDashboards ?? []
  }

  const getSentimentCommentsIndicatorName = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).sentimentCommentsIndicatorName
  }

  const getUseDefaultCriticalMoment = (): boolean => {
    const clientName = getClientName()
    return getClientConfigs(clientName).useDefaultCriticalMoment ?? false
  }

  const useDashboardDynamicPosition = (): boolean => {
    const clientName = getClientName()
    return getClientConfigs(clientName).useDashboardDynamicPosition ?? false
  }

  const getDashboardConfig = (dashboard: string): DashboardConfigInfo => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return (
      clientConfigs.dashboards[dashboard] ?? clientConfigs.dashboards.default
    )
  }

  const getClientLogoSmall = (): any => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.logoSmall
  }

  const getClientLogo = (): any => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.logo
  }

  const getClientshipLogo = (): any => {
    return logoClientship
  }

  const getClientMenuDashboards = (): DASHBOARDS[] => {
    const clientName = getClientName()
    const clientConfigs = getClientConfigs(clientName)
    return clientConfigs.menuDashboards
  }

  const getMetadataLoad = (): METADADATA_LOAD[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).metadata
  }

  const useCriticalMoments = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.CRITICAL_MOMENTS)
  }

  const useChannels = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.CHANNELS)
  }

  const useLogicalLocations = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.LOGICALS)
  }

  const useGeoLocations = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.GEOS)
  }

  const useClusters = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.CLUSTERS)
  }

  const useDynamicReports = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.DYNAMIC_REPORT_FIELDS)
  }

  const useWorkflows = (): boolean => {
    return getMetadataLoad().includes(METADADATA_LOAD.WORKFLOWS)
  }

  const getConfigFilters = (): string[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).filters
  }

  const supportsInternationalization = () => {
    const clientName = getClientName()
    return getClientConfigs(clientName).supportsInternationalization
  }

  const includesFilter = (filterName: string, userProfiles: UserProfile[]): boolean => {
    //console.log(`includes Filter ${filterName} `)
    //console.log(profileFilters)

    const configFilters = getConfigFilters()
    const profileFilters = userProfiles?.[0]?.config?.filters ?? []

    if (profileFilters.length > 0) {
      return profileFilters.includes(filterName)
    }

    return configFilters.includes(filterName)
  }

  const includesFilterDate = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.DATES, userProfiles)
  }

  const includesFilterGeo = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.GEOS, userProfiles)
  }

  const includesFilterLogical = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.LOGICALS, userProfiles)
  }

  const includesFilterCriticalMoment = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.CRITICAL_MOMENTS, userProfiles)
  }

  const includesFilterCluster = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.CLUSTERS, userProfiles)
  }

  const includesFilterChannel = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.CHANNELS, userProfiles)
  }

  const includesFilterDynamic = (userProfiles: UserProfile[]): boolean => {
    return includesFilter(FILTER_TYPES.DYNAMIC, userProfiles)
  }

  const getMsExcelReportExt = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).msExcelReportExt
  }

  const getOmoikaneConfig = (): OmoikaneConfig => {
    const clientName = getClientName()
    return getClientConfigs(clientName).omoikane
  }

  const getFilterCriticalMoments = (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ): CriticalMoment[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).getFilterCriticalMoments(
      filtersData,
      userProfiles,
      getCriticalMomentFilterType()
    )
  }

  const getFilterCriticalMomentDefault = (
    filtersData: Record<string, any>,
    userProfiles: UserProfile[]
  ) => {
    const clientName = getClientName()
    return getClientConfigs(clientName).getFilterCriticalMomentDefault(
      filtersData,
      userProfiles
    )
  }

  const getGeosRootId = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).geosRootId
  }

  const getLogicalsRootId = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).logicalsRootId
  }

  const getCriticalMomentsRootId = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).criticalMomentsRootId
  }

  const getCriticalMomentFilterType = (): 'tree' | 'select' => {
    const clientName = getClientName()
    return getClientConfigs(clientName).criticalMomentFilterType
  }

  const useCriticalMomentMultipleSelect = (): boolean => {
    const clientName = getClientName()

    if (getClientConfigs(clientName).criticalMomentMultipleSelect !== undefined) {
      //@ts-ignore
      return getClientConfigs(clientName).criticalMomentMultipleSelect
    }

    return false
  }

  const getFilterFromDateSubstract = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).filtersFromDateSubstract ?? 1
  }

  const getFilterToDateSubstract = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).filtersToDateSubstract ?? 0
  }

  const getDateFromParamName = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).dateFromParamName ?? 'dateTo'
  }

  const getDateToParamName = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).dateToParamName ?? 'dateTo'
  }



  const useBackDashboards = (): boolean => {
    const clientName = getClientName()
    return getClientConfigs(clientName).useBackDashboards ?? false
  }

  const getSurveyResponsesUrl = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).surveyResponses.url
  }

  const getActionPlansConfig = (): ActionPlansConfig => {
    const clientName = getClientName()
    return getClientConfigs(clientName).actionPlans
  }

  const getSurveyResponsesTableColumns = (): ColumnsType<any> | undefined => {
    const clientName = getClientName()
    return getClientConfigs(clientName).surveyResponses.tableColumns
  }

  const getSurveyResponsesTabs = (): SurveyResponsesTab[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).surveyResponses.tabs
  }

  const getSurveyResponsesType = (): string => {
    const clientName = getClientName()
    return getClientConfigs(clientName).surveyResponses.type
  }

  const getSurveyResponsesIndicators = (): SuveyResponsesIndicatorsConfig | undefined => {
    const clientName = getClientName()
    return getClientConfigs(clientName).surveyResponses.indicators
  }

  const getSurveysCountersValue = (countersInfo: SurveysCountInfo): number => {
    const clientName = getClientName()

    if (getClientConfigs(clientName).surveyResponses.surveysCountersValue !== undefined) {
      //@ts-ignore
      return getClientConfigs(clientName).surveyResponses.surveysCountersValue(countersInfo)
    }

    return countersInfo?.answered
  }

  const getAlertDetailFields = (): AlertDetailField[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).alerts.alertDetailFields ?? []
  }

  const getAlertHeaderFields = (): AlertHeaderField[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).alerts.alertHeaderFields ?? []
  }

  const useAlertWorkflows = (): boolean => {
    const clientName = getClientName()
    return getClientConfigs(clientName).alerts.useAlertWorkflows ?? false
  }

  const getRatingRanges = (): RatingRanges => {
    const clientName = getClientName()
    return getClientConfigs(clientName).ratingRanges
      ?? { defaultRatingMax: 10, defaultRatingMin: 0 }
  }

  const getHelpDefinitions = (): HelpDefinition => {
    const clientName = getClientName()
    return getClientConfigs(clientName).help
  }

  // Notifications
  const getNotificationsConfig = (): NotificationsConfig => {
    const clientName = getClientName()
    return getClientConfigs(clientName).notificationsConfig ?? { enabled: false }
  }


  // Customer data info
  const useUserPhoneNumber = (): boolean => {
    const clientName = getClientName()
    return getClientConfigs(clientName).userPhoneNumber
      ?? false;
  }

  const getPageSize = (): number => {
    const clientName = getClientName()
    return getClientConfigs(clientName).pageSize ?? 12;
  }
  const getQuestionsCustomerName = (): string[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).questionsCustomerName ?? [];
  }

  const getQuestionsOrder = (): QuestionOrder[] => {
    const clientName = getClientName()
    return getClientConfigs(clientName).questionsOrder ?? [];
  }
  
  const getSurveyResponseStats = ():  SurveyResponseStats => {
    const clientName = getClientName()
    return  getClientConfigs(clientName).surveyResponseStats ?? {showClientMode : false}
    
  }
  
  const getAlertStats = () : AlertStats | undefined => {
    const clientName = getClientName()
    return  getClientConfigs(clientName).alertStats ?? undefined
    
  }

  return {
    // General
    getClientName,
    getVocBackendUrl,
    getLcmBackendUrl,
    getCaseBackendUrl,
    getClientLogo,
    getClientLogoSmall,
    getClientshipLogo,
    getPublicUrl,
    supportsInternationalization,
    getDecimals,
    getRatingRanges,
    getHelpDefinitions,

    // Filters
    includesFilterDate,
    includesFilterGeo,
    includesFilterLogical,
    includesFilterCriticalMoment,
    includesFilterCluster,
    includesFilterChannel,
    includesFilterDynamic,
    useCriticalMoments,
    useChannels,
    useLogicalLocations,
    useGeoLocations,
    useClusters,
    useWorkflows,
    getGeosRootId,
    getLogicalsRootId,
    getFilterFromDateSubstract,
    getFilterToDateSubstract,
    getDateFromParamName,
    getDateToParamName,
    getCriticalMomentsRootId,
    getCriticalMomentFilterType,
    useCriticalMomentMultipleSelect,
    getFilterCriticalMoments,
    getFilterCriticalMomentDefault,

    // Dashboard
    getClientMenuDashboards,
    getDashboards,
    getDashboardConfig,
    useBackDashboards,
    getStaticDashboards,
    getDynamicDashboards,
    getSentimentCommentsIndicatorName,
    getUseDefaultCriticalMoment,
    useDashboardDynamicPosition,

    // Reports
    useDynamicReports,
    getMsExcelReportExt,

    // Omoikane
    getOmoikaneConfig,

    // Action Plan
    getActionPlansConfig,

    // Survey Responses
    getSurveyResponsesUrl,
    getSurveyResponsesTableColumns,
    getSurveyResponsesTabs,
    getSurveyResponsesType,
    getSurveyResponsesIndicators,
    getSurveysCountersValue,

    // Alerts
    getAlertDetailFields,
    getAlertHeaderFields,
    useAlertWorkflows,

    // Notifications
    getNotificationsConfig,

    // Customer data info
    useUserPhoneNumber,
    getPageSize,
    getQuestionsCustomerName,

    getQuestionsOrder,
    //todas las propiedades de SurveyStats
    getSurveyResponseStats,
    //todas las propiedades de AlertStats
    getAlertStats
  }
}

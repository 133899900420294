import { useNormalizedData } from '@nivo/pie'
import { groupMapperLateral, pieColors } from '../dashHelper'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { lineDetailedMonthAndGeoMapper } from '../widgetsLibrary/widgetsLine'



export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  diagramaIshikawa: {
    ...widgetsLibrary.diagramaIshikawa,
    //@ts-ignore
    config: {
      ...widgetsLibrary.diagramaIshikawa.config,
      mapper: (data, extra) => {
        return data
          // @ts-ignore
          ?.asMutable()
          .sort((item1: any, item2: any) => item2.count - item1.count)
      },
    }
  },
  mapByGeos: {
    ...widgetsLibrary.mapByGeos,
    //@ts-ignore
    config: {
      ...widgetsLibrary.mapByGeos.config,
      smallCircle: 10,
      mediumCirlce: 10,
      largeCircle: 10,
    }
  },
  // TODO: BORRAR DESPUES DE VERIFICAR QUE FUNCIONA EL EXTRAS
  atencionAgil: {
    title: '¿Cuál (es) fue el momento en el que lo atendimos ágilmente?',
    // [Column, Row]
    position: [1, 48],
    size: [2, 2],
    config: {
      id: 'SI_SERVICE_AGILE_OPTIONS',
      type: 'pie',
      fillMode: '',
      colors: pieColors,
      showValues: true,
      showSample: true,
      mapper: groupMapperLateral,
      itemWidth: 80,
      itemHeight: 20,
      //valueFormat: ' >-.1%',
      arcLabelsRadiusOffset: 1.15,
      itemDirection: 'left-to-right',
      anchor: 'top-left',
      direction: 'column',
      marginLeft: 250,
      legendTranslateX: -200,
      decimals: 2,
      indicators: [
        {
          indicator: 'SERVICE_AGILE_OPTIONS',
          //grouped: 'geoLocation',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            responseValues: { 1305: ['SI'] }
          },
        },
      ],
    },
  },
  // TODO: BORRAR DESPUES DE VERIFICAR QUE FUNCIONA EL EXTRAS
  atencionNoAgil: {
    title: '¿Cuál (es) fue el momento en el que no lo atendimos ágilmente?',
    // [Column, Row]
    position: [0, 50],
    size: [2, 2],
    config: {
      id: 'NO_SERVICE_AGILE_OPTIONS',
      type: 'pie',
      fillMode: '',
      colors: pieColors,
      showValues: true,
      showSample: true,
      mapper: groupMapperLateral,
      itemWidth: 80,
      itemHeight: 20,
      //valueFormat: ' >-.1%',
      arcLabelsRadiusOffset: 1.15,
      itemDirection: 'left-to-right',
      anchor: 'top-left',
      direction: 'column',
      marginLeft: 250,
      decimals: 2,
      legendTranslateX: -200,
      center: ['30%', '50%'],
      indicators: [
        {
          indicator: 'SERVICE_AGILE_OPTIONS',
          //grouped: 'geoLocation',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            responseValues: { 1305: ['NO'] },
          },
        },
      ],
    },
  },
  // TODO: este falta revisarlo!!!!!!
  generalSatisfactionDiesel: {
    title: 'Satisfacción general Diésel',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'satisfaction-diesel',
      type: 'pie',
      colors: pieColors,
      radius: [40, 85],
      center: ['40%', '55%'],
      showHistoric: false,
      showSample: false,
      decimals: 2,
      mapper: (data: any) => {
        return [
          {
            id: 0,
            value: data[0].value,
            label: 'satisfecho',
            group: 'satisfecho',
          },
          {
            id: 1,
            value: 100 - data[0].value,
            label: 'insatisfecho',
            group: 'insatisfecho',
          },
        ]
      },
      indicators: [
        {
          indicator: 'satisfaction',
          keyExtract: ['group', 'count', 'value'],
          label: 'group',
          value: 'value',
          singleton: true,
        },
      ],
    },
  },
}



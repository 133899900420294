import { COLORS_CONFIG_BAR, COLORS_CONFIG_GAUGE_5 } from '@components/widgets/apacheEchart/Commons'
import { getCommonWidget } from '../dashWidgetHelper'
import {
  bydAnsweredMapper,
  bydInvalidMapper,
  commonWidgets,
} from './commonWidgetsByd'
import { bydCustomerJourneyVentaMapper, bydTooltipTotalEncuestas } from './cxoResume'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'

export default {
  title: 'Nacional Venta Dashboard',
  description: 'Dashboard de Nacional Venta',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    /*  getCommonWidget({
       commonWidgets: commonWidgets,
       name: 'lineDetailedByMonthAndGeo',
       title: 'widgets.snVenta',
       position: [0, 0],
       size: [4, 4],
       extraConfigs: {
         backgroundColor: 'transparent',
         theme: 'dark',
       },
       indicator: 'VENTA_SATISFACCION',
       criticalMomentInclude: [1],
     }), */
    getLineDetailedRadar({
      mainIndicator: 'VENTA_SATISFACCION',
      radialIndicators: [
        'VENTA_SATISFACCION',
        'VENTA_NPS',
        'VENTA_ENTREGA',
        'VENTA_EJECUTIVO',
        'VENTA_FINANCIAMIENTO',
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 4],
      title: 'widgets.snByGeo',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
        initialVisibleChart: 1,
        decimals: 1,
      },
      criticalMomentInclude: [1],
      groupByLevel: 2,
      indicatorsNames: {
        'VENTA_SATISFACCION': 'Satisfacción',
        'VENTA_EJECUTIVO': 'Venta Consultiva',
        'VENTA_NPS': 'NPS',
        'VENTA_ENTREGA': 'Entrega',
        'VENTA_FINANCIAMIENTO': 'Financiamiento',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      position: [0, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      position: [1, 4],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
      position: [2, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
      position: [3, 4],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [0, 6],
      size: [2, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1
      }
    }),
    /*
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'semicircleGeneric',
      title: 'widgets.npsVenta',
      position: [1, 6],
      size: [1, 2],
      indicator: 'VENTA_NPS',
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 0
      },
      extras: {
        periods: 6
      }
    }),
    */
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.calidadDatosVenta',
      position: [2, 6],
      size: [1, 2],
      indicator: 'survey-response-counters',
      key: 'venta-calidad-datos-gauge',
      //indicator: 'venta-calidad-datos-gauge',
      //url: 'survey-responses/values',
      mapper: bydInvalidMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1,
        maxValue: 100,
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'survey-response-counters',
      key: 'venta-porc-respuestas-gauge',
      //indicator: 'venta-porc-respuestas-gauge',
      //url: 'survey-responses/values',
      position: [3, 6],
      size: [1, 2],
      mapper: bydAnsweredMapper,
      tooltipFormatter: bydTooltipTotalEncuestas,
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1,
        maxValue: 100,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: [0, 8],
      size: [4, 2],
      mapper: bydCustomerJourneyVentaMapper,
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1,
      extraConfigs: {
        decimals: 1,
        colors: COLORS_CONFIG_GAUGE_5,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoBest',
      title: 'widgets.bestGeoLocationsVenta',
      position: [0, 10],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#88DD76'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByGeoWorst',
      title: 'widgets.worstGeoLocationsVenta',
      position: [1, 10],
      size: [1, 2],
      extraConfigs: {
        backgroundColor: '#501C78',
        titleBackgroundColor: '#db7476'
      },
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'mapByGeos',
      title: 'Mapa de estaciones',
      position: [2, 10],
      size: [2, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentInclude: [1],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionBest',
      title: 'widgets.bestRotativasVenta',
      position: [0, 12],
      size: [1, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1,
      extraConfigs: {
        fontSize: 20
      },
      extras: {
        featureExclude: ['groupByTopLocation'],
        groupNameFormat: 'description',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByQuestionWorst',
      title: 'widgets.worstRotativasVenta',
      position: [1, 12],
      size: [1, 2],
      indicator: 'VENTA_ROTATIVAS',
      criticalMomentId: 1,
      extraConfigs: {
        fontSize: 20
      },
      extras: {
        featureExclude: ['groupByTopLocation'],
        groupNameFormat: 'description',
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'widgets.bestTagsVenta',
      position: [2, 12],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      extras: {
        resultType: 'top',
        trunc: "HALF_DOWN",
        topSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'widgets.worstTagsVenta',
      position: [2, 13],
      size: [2, 1],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      extras: {
        resultType: 'bottom',
        trunc: "HALF_UP",
        bottomSize: 3
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: 'Análisis de sentimientos',
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
      position: [0, 14],
      size: [4, 3],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      //title: 'Diagrama Ishikawa',
      position: [0, 17],
      size: [4, 2],
      indicator: 'SENTIMENT',
      criticalMomentId: 1,
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.zonalSatisfaction',
      key: 'venta-satisfaccion-zona',
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      position: [0, 19],
      size: [4, 2],
      extraIndicatorConfigs: {
        sort: { value: 'desc' },
      },
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: true,
      },
      extras: {
        groupByLevel: 2
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'barByGeo',
      title: 'widgets.distribuidorSatisfaction',
      key: 'venta-satisfaccion-dist',
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      position: [0, 21],
      size: [4, 4],
      mapper: (data: any) => {
        return data.sort((a: any, b: any) => b.value - a.value)
      },
      extraConfigs: {
        colors: COLORS_CONFIG_BAR,
        wrapTexts: false,
        rotateXAxisLegend: true,
      },
      extras: {
        groupByLevel: 3,
      },
    }),
  ],
} as DasboardConfig

import { isGeoLocationLeaf } from '../dashHelper'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'

export const GREEN_1 = '#88DD76'
export const GREEN_2 = '#AEDD76'
export const ORANGE = '#F3AF5A'
export const BLUE = '#8CF0EA'
export const RED_2 = '#DB7476'
export const RED_1 = '#CD4245'
export const YELLOW = '#F1E713'

export const geoLeafCheckRender = (filters: FiltersUI, filtersData: Record<string, any>) => {
  /*const excluded = ['postventa-satisfaccion-dist', 'postventa-satisfaction-zona']
    .includes(wdg?.config?.id ?? '') && isGeoLocationLeaf(filters, filtersData)
  return filters !== undefined
    && filters.criticalMoments !== undefined
    && filters.criticalMoments !== null
    && filters.criticalMoments.includes(2)
    && !excluded*/
}



export const commonWidgets: CommonWidget = {
  ...widgetsLibrary
}

export const MITSUBISHI_SCALE_3_COLORS = {
  'promoters': GREEN_1,
  'passives': ORANGE,
  'detractors': RED_2,
  'positive': GREEN_1,
  'neutral': ORANGE,
  'negative': RED_1,
  1: GREEN_1,
  2: ORANGE,
  3: RED_1,
}
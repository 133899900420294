import { pieColors } from '../dashHelper'
import { COLORS_CONFIG } from '../profuturo/commonWidgetsProfuturo'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'



export const getHaLineDetailedByMonthAndGeo = (
    props: {
        key: string,
        indicator: string,
        title: string,
        position?: number[] | ((filters: FiltersUI) => number[]),
        size?: number[],
        extraConfigs?: { [key: string]: any },
        criticals: { key: string, criticalMoments: number[], label: string }[],
        formula: string
    }
) => {
    const extraConfigs = {
        ...props.extraConfigs,
        criticals: props.criticals
    }

    return {
        title: props.title ?? 'FALTA',
        // [Column, Row]
        position: props.position ?? [0, 0],
        size: props.size ?? [4, 3],
        widgetName: props.key,
        config: {
            id: `line-detailed-by-month-and-geo-${props.key}`,
            type: 'lineDetailed',
            fillMode: '',
            colors: pieColors,
            showValues: false,
            shadow: false,
            ...extraConfigs,
            mapper: (data: any, extra: any) => {
                //console.log('getHaLineDetailedByMonthAndGeo mapper')
                //console.log(extra)

                const criticals = extra?.config?.criticals
                //console.log(criticals)

                const criticalMetrics = criticals?.map !== undefined
                    ? criticals?.map((cm: any) => {
                        return data[`grouped-by-critical-moment-${cm.key}`]?.map !== undefined
                            ? [...data[`grouped-by-critical-moment-${cm.key}`]
                                ?.map((item: any) => ({
                                    label: cm.label,
                                    value: item.value
                                }))]
                            : []
                    }).flat(1)
                    : []

                const metrics = [
                    { label: 'General', value: data['data-gral'] },
                    ...criticalMetrics
                ]

                const dataCriticals = criticals?.map !== undefined
                    && criticals?.map((cm: any) => {
                        return data[`grouped-by-month-${cm.key}`]?.map !== undefined
                            ? [
                                ...data[`grouped-by-month-${cm.key}`]
                                    ?.map((item: any, idx: number) => ({
                                        ...item,
                                        id: idx,
                                        category: item.groupName ? item.groupName : item['group_0'],
                                        groupName: cm.label,
                                    }))
                            ] : []
                    }).flat(1)

                const dataGral = data['grouped-by-month-gral']?.map !== undefined
                    && data['grouped-by-month-gral']
                        ?.map((item: any, idx: number) => ({
                            ...item,
                            id: idx,
                            category: item.groupName ? item.groupName : item['group_0'],
                            groupName: 'General',
                        }));

                const values = [...dataGral, ...dataCriticals]

                const finalData = {
                    //@ts-ignore
                    value: values.sort((a: any, b: any) => {
                        return  a?.['group_0'].localeCompare(b?.['group_0'])
                        //|| a?.groupName.localeCompare(b?.groupName);
                    }),
                    metrics: metrics
                }

                //console.log(`getHaLineDetailedByMonthAndGeo ${extra?.title}`)
                //console.log(values)

                return finalData
            },
            indicators: [
                ...props.criticals?.map((cm: any) => ({
                    key: `grouped-by-month-${cm.key}`,
                    indicator: props.indicator,
                    grouped: 'formattedMonth,criticalMoment',
                    dataType: 'single',
                    keyExtract: [
                        'group_0',
                        'group_1',
                        'groupId_0',
                        'value',
                        'groupName'
                    ],
                    label: 'group',
                    singleton: true,
                    sort: { sortBy: 'group_0', value: 'asc' },
                    extras: {
                        periods: 6,
                        groupNameFormat: 'description',
                        criticalMomentInclude: cm.criticalMoments,
                        formula: props.formula,
                        groupByLevel: 0,
                        test: 'valor de test'
                    },
                })),
                ...props.criticals?.map((cm: any) => ({
                    key: `grouped-by-critical-moment-${cm.key}`,
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName'],
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        criticalMomentInclude: cm.criticalMoments,
                        formula: props.formula
                    },
                })),

                {
                    key: 'grouped-by-month-gral',
                    indicator: props.indicator,
                    grouped: 'formattedMonth,criticalMoment',
                    dataType: 'single',
                    keyExtract: [
                        'group_0',
                        'group_1',
                        'groupId_0',
                        'value',
                        'groupName'
                    ],
                    label: 'group',
                    singleton: true,
                    extras: {
                        periods: 6,
                        groupNameFormat: 'description',
                        groupByLevel: 0,
                        formula: props.formula
                    },
                },
                {
                    key: 'data-gral',
                    indicator: props.indicator,
                    keyExtract: ['value'],
                    value: 'value',
                    dataType: 'single',
                    singleton: false,
                    extras: {},
                },

                {
                    key: 'grouped-by-geo',
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName'],
                    grouped: 'geoLocation',
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        periods: 6,
                        groupNameFormat: 'description'
                    },

                },

            ],
        },
    }
}


export const getHaBarDetailedByMonthAndGeo = (
    props: {
        key: string,
        indicator: string,
        title: string,
        position?: number[] | ((filters: FiltersUI) => number[]),
        size?: number[],
        extraConfigs?: { [key: string]: any },
        criticals: { key: string, criticalMoments: number[], label: string }[],
        formula: string
    }
) => {
    const extraConfigs = {
        ...props.extraConfigs,
        criticals: props.criticals
    }

    return {
        title: props.title ?? 'FALTA',
        // [Column, Row]
        position: props.position ?? [0, 0],
        size: props.size ?? [4, 3],
        widgetName: props.key,
        config: {
            id: `bar-detailed-by-month-and-geo-leaf-${props.key}`,
            type: 'barDetailed',
            fillMode: '',
            colors: COLORS_CONFIG,
            showValues: false,
            wrapTexts: true,
            shadow: false,
            rotateXAxisLegend: true,
            ...extraConfigs,
            mapper: (data: any, extra: any) => {
                const criticals = extra?.config?.criticals

                const criticalMetrics =
                    criticals?.map !== undefined
                        ? criticals?.map((cm: any) => {
                            return data[`grouped-by-critical-moment-${cm.key}`]?.map !== undefined
                                ? [
                                    ...data[`grouped-by-critical-moment-${cm.key}`]?.map((item: any) => ({
                                        label: `${item.group} - ${cm.label}`,
                                        value: item.value
                                    }))
                                ] : []
                        }).flat(1)
                        : []

                const metrics = [
                    { label: 'General', value: data['data-gral'] },
                    ...criticalMetrics
                ];

                const sortedValue = [...data['grouped-by-geo']]
                    ?.map((item: any, idx: number) => {
                        var groupName = item.groupName ? item.groupName : item.group;
                        groupName = groupName.replaceAll('Hospital ', '');
                        return {
                            ...item,
                            id: idx,
                            value: item.value,
                            group: groupName,
                            label: groupName
                        };
                    }).sort((a, b) => b.value - a.value);

                return {
                    value: sortedValue,
                    metrics: metrics
                };
            },
            indicators: [
                {
                    key: 'grouped-by-geo',
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName'],
                    grouped: 'geoLocation',
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        groupNameFormat: 'description',
                        groupByLevel: 3
                    },
                },
                {
                    key: 'grouped-by-geo-gral',
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName'],
                    grouped: 'geoLocation',
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        groupNameFormat: 'description',
                        groupByLevel: 0
                    },
                },
                {
                    key: 'data-gral',
                    indicator: props.indicator,
                    keyExtract: ['value'],
                    value: 'value',
                    dataType: 'single',
                    singleton: false,
                    extras: {},
                },
                ...props.criticals?.map((cm: any) => ({
                    key: `grouped-by-critical-moment-${cm.key}`,
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName', 'group_0', 'group_1'],
                    grouped: 'geoLocation',
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        periods: 6,
                        groupNameFormat: 'description',
                        criticalMomentInclude: cm.criticalMoments,
                        formula: props.formula
                    }
                }))
            ],
        },
    }
}


export const getHaLineDetailedByLeafMonthAndGeo = (
    props: {
        key: string,
        indicator: string,
        title: string,
        position?: number[] | ((filters: FiltersUI) => number[]),
        size?: number[],
        extraConfigs?: { [key: string]: any },
    }
) => {
    return {
        title: props.title ?? 'FALTA',
        // [Column, Row]
        position: props.position ?? [0, 0],
        size: props.size ?? [4, 3],
        widgetName: props.key,
        config: {
            ...widgetsLibrary.lineDetailedByMonthAndGeo.config,
            id: `line-detailed-by-month-and-geo-leaf-${props.key}`,
            decimals: 0,
            ...props.extraConfigs,
            mapper: (data: any) => {
                //console.log('haLeafLineDetailedByGeoMapper!!!!!!!!')
                //console.log(data)

                const metrics = [
                    { label: 'General', value: data['data-gral'] },
                    ...data['grouped-by-geo']?.map((item: any) => ({
                        label: item.group,
                        value: item.value
                    })),
                    ...data['grouped-by-geo-leaf']?.map((item: any) => ({
                        label: item.group,
                        value: item.value
                    }))
                ]

                return {
                    //@ts-ignore
                    value: data['grouped-by-month-and-geo']?.map((item: any, idx: number) => ({
                        ...item,
                        id: idx,
                        category: item.groupName ? item.groupName : item['group_0'],
                        groupName: item['group_1'] ? item['group_1'] : item.label,
                    })),
                    metrics: metrics
                }
            },
            indicators: [
                {
                    key: `grouped-by-month-and-geo`,
                    indicator: props.indicator,
                    grouped: 'formattedMonth,geoLocation',
                    keyExtract: [
                        'group_0',
                        'group_1',
                        'groupId_0',
                        'value',
                        'groupName'
                    ],
                    label: 'group',
                    value: 'value',
                    sort: { sortBy: 'group_0', value: 'asc' },
                    singleton: true,
                    extras: {
                        periods: 6
                    },
                },
                {
                    key: `data-gral`,
                    indicator: props.indicator,
                    keyExtract: ['value'],
                    value: 'value',
                    dataType: 'single',
                    singleton: false,
                    extras: {},
                },
                {
                    key: `grouped-by-geo`,
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName'],
                    grouped: 'geoLocation',
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        groupNameFormat: 'description',
                        groupByLevel: 1
                    },
                },
                {
                    key: `grouped-by-geo-leaf`,
                    indicator: props.indicator,
                    keyExtract: ['value', 'group', 'groupName'],
                    grouped: 'geoLocation',
                    dataType: 'single',
                    singleton: true,
                    extras: {
                        groupNameFormat: 'description'
                    },
                }
            ],
        }
    }
}
import { getCommonWidget } from '../../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, haWordCountWithLinksMapper  } from './../commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_14',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
     }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿Qué lo motivó a elegirnos para su atención?',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: true,
    },
    indicator: 'servicios_externos_diagnostico_pregunta_1',
    criticalMomentInclude: [12051]
  }),
  getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: 'Indique por favor el servicio en que recibió atención',
      position: [0, 3],
      size: [3, 2],
      extraConfigs: {
        showSample: true,
      },
      indicator: 'servicios_externos_diagnostico_pregunta_3',
      criticalMomentInclude: [12051]
    }),
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 5],
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción General con el servicio',
    position: [0, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_6',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención del personal que lo recibió en el servicio',
    position: [1, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_7',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Claridad de las indicaciones para presentarse al estudio (aseo, ayuno, etc.)',
    position: [2, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_8',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Tiempo de espera para realizarse el estudio',
    position: [3, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_9',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
    }
  }),
 {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 8],
    size: [4, 1],
   },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Servicio y habilidades del personal técnico, de enfermería o médico durante el procedimiento',
    position: [0, 9],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_10',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Condiciones físicas y limpieza de las instalaciones',
    position: [1, 9],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'servicios_externos_diagnostico_pregunta_11',
    criticalMomentInclude: [12051],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'textAnalysis',
     position:[0,11], 
     size: [2, 3],
     indicator: 'SENTIMENT',
     criticalMomentInclude: [12051],
     extraConfigs:{
        hideBars: true
     }
   }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'wordCountWithLinks',
     title: 'Nube de Palabras / Frecuencia en comentarios',
     position: [2, 11],
     size: [2, 3],
     mapper: haWordCountWithLinksMapper,
     criticalMomentInclude: [12051]
   }),
   
]

export default {
  title: 'Hospital Angeles Servicios Externos de Diagnóstico',
  description: 'Hospital Angeles Servicios Externos de Diagnóstico',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig

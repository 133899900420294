import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_PIE_COLORS, commonWidgets, getProfuturoFacilidad, getProfuturoNpsAcumulado, getProfuturoNpsPorMes, getProfuturoSatisfaccion, getProfuturoCustomerJourney, PRODUTURO_SCALE_5_COLORS_REVERSE } from "../commonWidgetsProfuturo";

export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            position: [0, 0],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getProfuturoNpsAcumulado({
            position: [0, 1],
            size: [1, 2],
            indicator: 'app_pregunta_15'
        }),
        getProfuturoNpsPorMes({
            position: [1, 1],
            size: [1, 2],
            indicator: 'app_pregunta_15',
            helpText: 'A partir de tu experiencia con Profuturo Móvil, en una escala de 0 a 10, ¿qué tan probable es que recomiendes a Profuturo con un amigo o familiar?'
        }),
        getProfuturoSatisfaccion({
            position: [2, 1],
            size: [1, 2],
            indicator: 'app_pregunta_20',
            helpText: 'A partir de tu interacción con la aplicación ¿Qué tan satisfecho estás con las funciones y características disponibles?',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS_REVERSE,
            },
            mapper: (data: any): any => {
                const details = data?.details
                    // @ts-ignore
                    .asMutable()
                    .sort((item1: any, item2: any) => item2?.groupId - item1?.groupId)
                return {
                    ...data,
                    value: details?.[0]?.value ?? data?.value,
                    details: details
                }
            },
            historicFormula: 'count-number',
            historicFormulaConfig: '',
            historicGrouped: 'formattedMonth,responseValue',
            historicGroupByResponseValueId: 173227018,
            historicMapper: (data: any[], extra?: any): any => {

                const totalsByMonth: Record<string, number> = {};

                data.forEach(item => {
                    if (!totalsByMonth[item.groupName]) {
                        totalsByMonth[item.groupName] = 0;
                    }
                    totalsByMonth[item.groupName] += item.count;
                });

                const percentageData = data.map(item => ({
                    ...item,
                    value: ((item.count / totalsByMonth[item.groupName]) * 100).toFixed(0)
                })).filter(item => item.group == 'Muy satisfecho');

                return percentageData
            }


        }),
        getProfuturoFacilidad({
            position: [3, 1],
            size: [1, 2],
            indicator: 'app_pregunta_4',
            helpText: 'Pensando en la última consulta o trámite que hiciste a través de Profuturo Móvil, ¿qué tan fácil fue realizarlo?'
        }),
        {
            title: 'Quiebres y Motivadores en la satisfacción',
            position: [0, 3],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Funciones que esperabas encontrar y no están disponibles',
            position: [0, 4],
            size: [2, 2],
            indicator: 'app_pregunta_21',
            helpText: '¿Qué funciones o características esperabas encontrar en la aplicación y no están disponibles?'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Funciones que más valoraste',
            position: [2, 4],
            size: [2, 2],
            indicator: 'app_pregunta_22',
            helpText: '¿Qué funciones o características valoraste más al utilizar la aplicación?'
        }),
        {
            title: 'Quiebres y Motivadores en la facilidad',
            position: [0, 6],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes insatisfechos',
            position: [0, 7],
            size: [2, 2],
            indicator: 'app_pregunta_23',
            helpText: '¿Por qué resultó de esa manera? ',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes neutros',
            position: [2, 7],
            size: [2, 2],
            indicator: 'app_pregunta_25',
            helpText: '¿Qué podríamos hacer para mejorar la facilidad?',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Facilidad: clientes satisfechos',
            position: [0, 9],
            size: [2, 2],
            indicator: 'app_pregunta_27',
            helpText: '¿Por qué resultó fácil? ',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Último trámite consultado',
            position: [2, 9],
            size: [2, 2],
            indicator: 'app_pregunta_6',
            mapper: (data: any): any => {
                return data.filter((item: any) => item.group !== '6' && item.group !== '7')

            },
            helpText: '¿Cuál fue el último trámite o consulta que realizaste a través de Profuturo Móvil?',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
                itemGap: 6,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMediumSiNo',
            title: 'Resolución del Trámite',
            position: [0, 11],
            size: [2, 2],
            helpText: '¿Pudiste resolver tu trámite o consulta?',
            indicator: 'app_pregunta_7',
            extraConfigs: {
                itemGap: 10,
                center: ['27%', '55%'],
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Efectividad: Clientes insatisfechos',
            position: [2, 11],
            size: [2, 2],
            helpText: '¿Por qué no pudiste resolver tu trámite o consulta? ',
            indicator: 'app_pregunta_29',
            extraConfigs: {
                itemGap: 10,
                colors: PRODUTURO_PIE_COLORS,
                center: ['27%', '55%'],
            }
        }),
        getProfuturoCustomerJourney({
            id: 'cj-mobile',
            indicators: [{ name: 'app_pregunta_4', formula: 'count-number' },
            { name: 'app_pregunta_15', formula: 'nps-10' }
            ],
            title: 'Customer Journey: Top Two Box',
            position: [0, 13],
            size: [2, 2],
        }),
        {
            title: 'Atributos en la experiencia de Grado de SATISFACCIÓN',
            position: [0, 15],
            size: [4, 1],
            type: 'label',
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La rapidez y facilidad para ingresar a la app',
            position: [0, 16],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'app_pregunta_10'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La facilidad para encontrar la información / trámite que necesitaba',
            position: [0, 17],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'app_pregunta_11'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El modo en que pude resolver mis consultas / trámites',
            position: [0, 18],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'app_pregunta_12'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'Lo simple y sencillo que resultó navegar en la app',
            position: [0, 19],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'app_pregunta_13'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La claridad de la información que me brindó la app',
            position: [0, 20],
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'app_pregunta_14'
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 21],
            size: [4, 4],
            indicator: 'SENTIMENT'
        }),
    ]
} as DasboardConfig

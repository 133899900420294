import { filterGeos } from '@utils/GeoUtils'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'
import { getCommonWidget } from '../dashWidgetHelper'
//import { COLORS_CONFIG } from '@components/widgets/apacheEchart/Commons'
import Color from 'color'
import CriticalMoments from 'routes/CriticalMoments'

type IndicatorsMapper = {
  [key: string]: string
}


export const GENERAL_INDICATORS: IndicatorsMapper = {
  ATENCION_GENERAL: 'Atención',
  CSAT_GENERAL: 'Satisfacción',
  FACILIDAD_GENERAL: 'Facilidad',
  NPS_GENERAL: 'NPS',
  TIEMPO_ESPERA_GENERAL: 'Satisfacción tiempo de espera',
  GROUP: 'Asesor',
  SURVEY_COUNT: '# Respuestas'
}

export const PROFUTURO_GREEN_1 = '#88DD76'
export const PROFUTURO_GREEN_2 = '#AEDD76'
export const PROFUTURO_ORANGE = '#F3AF5A'
export const PROFUTURO_BLUE = '#8CF0EA'
export const PROFUTURO_RED_2 = '#DB7476'
export const PROFUTURO_RED_1 = '#CD4245'
export const PROFUTURO_YELLOW = '#F1E713'//'#D7DF01' //'#FFFF00'


export const PRODUTURO_SCALE_3_COLORS = {
  'promoters': PROFUTURO_GREEN_1,
  'passives': PROFUTURO_ORANGE,
  'detractors': PROFUTURO_RED_1,
  'positive': PROFUTURO_GREEN_1,
  'neutral': PROFUTURO_ORANGE,
  'negative': PROFUTURO_RED_1,
  1: PROFUTURO_GREEN_1,
  2: PROFUTURO_ORANGE,
  3: PROFUTURO_RED_1,
}

export const PRODUTURO_SCALE_3_COLORS_SMOKED = {
  'promoters': Color(PROFUTURO_GREEN_1).alpha(0.5).toString(),
  'passives': Color(PROFUTURO_ORANGE).alpha(0.5).toString(),
  'detractors': Color(PROFUTURO_RED_1).alpha(0.5).toString(),
  'positive': Color(PROFUTURO_GREEN_1).alpha(0.5).toString(),
  'neutral': Color(PROFUTURO_ORANGE).alpha(0.5).toString(),
  'negative': Color(PROFUTURO_RED_1).alpha(0.5).toString(),
}

export const PRODUTURO_SCALE_4_COLORS = {
  1: PROFUTURO_RED_1,
  2: PROFUTURO_ORANGE,
  3: PROFUTURO_GREEN_2,
  4: PROFUTURO_GREEN_1,
}

export const PRODUTURO_SCALE_4_COLORS_INVERSE = {
  3: PROFUTURO_RED_1,
  2: PROFUTURO_ORANGE,
  1: PROFUTURO_GREEN_2,
  0: PROFUTURO_GREEN_1,
}

export const PRODUTURO_SCALE_5_COLORS = {
  1: PROFUTURO_GREEN_1,
  2: PROFUTURO_GREEN_2,
  3: PROFUTURO_ORANGE,
  4: PROFUTURO_RED_2,
  5: PROFUTURO_RED_1,
}

export const PRODUTURO_SCALE_5_COLORS_REVERSE = {
  5: PROFUTURO_GREEN_1,
  4: PROFUTURO_GREEN_2,
  3: PROFUTURO_ORANGE,
  2: PROFUTURO_RED_2,
  1: PROFUTURO_RED_1,
}

export const COLORS_CONFIG = [
  {
    color: PROFUTURO_RED_1,
    condition: '<=' as const,
    key: 'value',
    value: 39,
  },
  {
    color: PROFUTURO_ORANGE,
    condition: '>' as const,
    key: 'value',
    value: 39,
  },
  {
    color: PROFUTURO_GREEN_1,
    condition: '>' as const,
    key: 'value',
    value: 70,
  },
]

export const PROFUTURO_SACLE_5_COLORS_EXTENDS_RED = [
  ...Object.values(PRODUTURO_SCALE_5_COLORS),
  '#b53032',
  '#a12b2d',
  '#8d2527',
  '#792022',
  '#651b1c',
  '#511516',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011',
  '#3c1011'
]

export const PRODUTURO_PIE_SINO_COLORS = {
  'No': PROFUTURO_RED_1,
  'Sí': PROFUTURO_GREEN_1,
  'Otro': PROFUTURO_ORANGE,
  0: PROFUTURO_GREEN_1,
  1: PROFUTURO_RED_1,
  2: PROFUTURO_ORANGE
}

export const PRODUTURO_PIE_COLORS = [
  //NEGATIVE_1_RED,
  '#34C0B8',
  '#FFD01B',
  '#F5B49F',
  '#B29BC2',
  '#777933',
  '#A668A8',
  '#9A9CCD',
  '#9C6D76',
  '#C0CB0B',
  '#878972',
  '#0C84AD',
  '#C357F2',
  '#C357F2',
  '#986CAF',
]

export const getTomorrowDate = (): string => {
     const today = new Date()
     today.setDate(today.getDate() + 1)
     return today.toISOString().split('T')[0]
 }
          
export const getExtrasFechas: { [key: string]: any } = {
    dateFrom: '2018-01-01',
    dateTo: getTomorrowDate(),
    periods: 20
}

export const getMapperBimestralHistorico = (data: any): any[] => {
    const excludeGroups = ['-3', '-6']

    return data
        .filter(({ group }: any) => excludeGroups.some(exclude => group.includes(exclude)))
        .map(({ group, groupName, label, ...rest }: any) => {
            const replaceGroup = (str: string) =>
                str.includes('-3') ? str.replace('-3', '-1') : str.replace('-6', '-2');

            return {
                ...rest,
                group: replaceGroup(group),
                groupName: replaceGroup(groupName),
                label: replaceGroup(label),
            }
        })
}

    





export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  npsMultipleTrend: {
    title: 'TODO',
    position: [0, 0],
    size: [1, 2],
    config: {
      id: 'nps-multiple-trend',
      type: 'multipleTrend',
      color: '#CCC',
      showHistoric: false,
      showSample: false,
      categories: ['promoters', 'passives', 'detractors'],
      colors: PRODUTURO_SCALE_3_COLORS,
      decimals: 2,
      useCurrent: 0,
      mapper: (data: any): any => {
        return data.map((item: any, idx: number) => ({ ...item, id: idx }))
      },
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'formattedMonth',
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'count',
          ],
          value: 'value',
          singleton: true,
          extras: {
            periods: 6
          }
        }
      ]
    }
  },
  npsMultipleTrendFourMonthPeriod: {
    title: 'TODO',
    position: [0, 0],
    size: [1, 2],
    config: {
      id: 'nps-multiple-trend-four-Month-Period',
      type: 'multipleTrend',
      color: '#CCC',
      showHistoric: false,
      showSample: false,
      categories: ['promoters', 'passives', 'detractors'],
      colors: PRODUTURO_SCALE_3_COLORS,
      decimals: 2,
      useCurrent: 0,
      mapper: (data: any): any => {
        return data.map((item: any, idx: number) => {
          return { ...item, groupName: item.group, id: idx };
        })
      },
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'responseValue',
          label: 'group',
          value: 'value',
          groupByResponseValueId: 173003100,
          keyExtract: [
            'passives',
            'detractors',
            'promoters',
            'passiveCount',
            'detractorCount',
            'promoterCount',
            'value',
            'groupName',
            'count',
            'groupId',
            'group'

          ],
          singleton: true
        }
      ]
    }
  },
  genericSingleTrend: {
    title: 'TODO',
    // [Column, Row]
    position: [1, 1],
    size: [1, 2],
    config: {
      id: 'generic-single-trend',
      type: 'singleTrend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      decimals: 2,
      indicators: [
        {
          indicator: 'TODO',
          grouped: 'formattedMonth',
          keyExtract: ['value', 'groupName', 'count'],
          value: 'value',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
            periods: 6,
            //model: 'avg'
          },
        },
      ],
    },
  },
  textAnalysis: {
    ...widgetsLibrary.textAnalysis,
    //@ts-ignore
    config: {
      ...widgetsLibrary.textAnalysis.config,
      //colors: ['#A8EB12', '#D95A26', '#00B287'],
      colors: PRODUTURO_SCALE_3_COLORS_SMOKED,
      groupKeys: ['positive', 'neutral', 'negative'],
      mapper: (data: any): any => {
        const newData = data !== undefined
          ? {
            ...data,
            categories: data.categories
            /*.filter((cat: any) => cat.group !== 'DEFAULT')
            .map((cat: any) => ({
              ...cat,
              tags: cat.tags.filter((cat: any) => cat.group !== 'DEFAULT')
            }))
            */

          }
          : {}
        return newData
      },
    }
  },
  wordCount: {
    title: 'FALTA texto',
    position: [4, 2],
    size: [2, 2],
    config: {
      id: 'word-count',
      type: 'wordCloud',
      mapper: (data: any): any => {
        return data.filter((item: any) => item.group !== 'DEFAULT')
      },
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['count', 'value', 'group'],
          singleton: true,
          extras: {
            formula: 'word-count'
          }
        }
      ]
    }
  },

  countNumberSemicircle: {
    ...widgetsLibrary.countNumberSemicircle,
    //@ts-ignore
    config: {
      ...widgetsLibrary.countNumberSemicircle.config,
      showValue: true,
      valuePosition: 'inner',
      center: ['50%', '46%'],
      radius: ['40%', '90%'],
      decimals: 0,
      itemHeight: 10,
      padAngle: 10,
      colors: PRODUTURO_SCALE_5_COLORS,
      itemBorderRadius: 5,
      mapper: (data: any): any => {
        const sortedValues = data?.details?.asMutable()
          // @ts-ignore
          .sort((c1, c2) => c2.groupId > c1.groupId)
          .map((item: any) => item.value)

        return {
          ...data,
          value: sortedValues !== undefined
            && sortedValues.length > 0
            ? sortedValues[0]
            : data.value
        }
      },
    }
  },

  semicircleGeneric: {
    ...widgetsLibrary.semicircleGeneric,
    //@ts-ignore
    config: {
      ...widgetsLibrary.semicircleGeneric.config,
      colors: PRODUTURO_SCALE_3_COLORS,
      itemBorderRadius: 5,
      showValue: true,
      valuePosition: 'inner',
      decimals: 0,
    }
  },

  stackedBar: {
    ...widgetsLibrary.stackedBar,
    //@ts-ignore
    config: {
      ...widgetsLibrary.stackedBar.config,
      colors: PRODUTURO_SCALE_5_COLORS,
      decimals: 0,
    }
  },
  pieGenericMedium: {
    ...widgetsLibrary.pieGenericMedium,
    //@ts-ignore
    config: {
      ...widgetsLibrary.pieGenericMedium.config,
      decimals: 0,
    }
  },
  pieGenericLarge: {
    ...widgetsLibrary.pieGenericLarge,
    //@ts-ignore
    config: {
      ...widgetsLibrary.pieGenericLarge.config,
      decimals: 0,
    }
  },
  pieGenericMediumSiNo: {
    ...widgetsLibrary.pieGenericMedium,
    //@ts-ignore
    config: {
      ...widgetsLibrary.pieGenericMedium.config,
      colors: PRODUTURO_PIE_SINO_COLORS,
      decimals: 0,
    }
  },
  multipleValueStackedBar: {
    title: 'FALTA',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'bar-by-question',
      type: 'stackedBar',
      colors: PROFUTURO_SACLE_5_COLORS_EXTENDS_RED,
      percentageScale: false,
      showValues: true,
      mapper: (data: any): any => {

        let groupedValues: { [key: number]: { count: number, group: number, value: number } } = {};
        let acumCount = 0;

        data
          .filter((item: any) => {
            let groupNum = parseInt(item.group);
            return !isNaN(groupNum) && groupNum > 0;// && groupNum <= 20;
          })
          .forEach((item: any) => {
            let groupNum = parseInt(item.group);
            if (!groupedValues[groupNum]) {
              groupedValues[groupNum] = { count: 0, group: groupNum, value: 0 };
            }
            groupedValues[groupNum].count += item.count;
            acumCount += item.count;
          });

        return Object.values(groupedValues)
          .map((item: any) => {
            item.value = acumCount > 0 ? Math.round((item.count * 100) / acumCount) : 0;
            return item;
          })
          .sort((c1: any, c2: any) => c1.group - c2.group);
      },
      indicators: [
        {
          indicator: 'FALTA',
          keyExtract: ['value', 'group', 'count'],
          label: 'textAnswer',
          grouped: 'textAnswer',
          value: 'value',
          singleton: true
        },
      ],
    },
  },
  customerJourney: {
    title: 'Customer Journey con Asesor',
    // [Column, Row]
    position: [0, 33],
    size: [4, 2],
    config: {
      id: 'multiple-gauge-by-question',
      type: 'multipleGauge',
      fillMode: '',
      showValues: true,
      showHistoric: false,
      mapper: (data: any, extra: any) => {
        //console.log("data")
        //console.log(data)
        return Object.keys(data).flatMap(key =>
          data[key]?.map((item: any) => ({
            label: 'widgets.indicatorId.' + item.groupId,
            value: item.value
          })) || []
        );

      },
      indicators: [
        {
          indicator: 'autoserv_con_asesor_pregunta_3',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
        {
          indicator: 'autoserv_con_asesor_pregunta_5',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
        {
          indicator: 'autoserv_con_asesor_pregunta_9',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
        {
          indicator: 'autoserv_con_asesor_pregunta_14',
          grouped: 'question',
          keyExtract: ['group', 'value', 'groupId'],
          label: 'group',
          value: 'value',
          singleton: true,
          extras: {
            groupNameFormat: 'description',
          },
        },
      ],
    },
  },
}

export const getProfuturoNpsPorMes = (props: {
  indicator: string,
  title?: string,
  position?: number[],
  size: number[],
  helpText?: string
  extras?: { [key: string]: any },
  extraConfigs?: { [key: string]: any },
  historicGrouped?: string,
  historicGroupByResponseValueId?: number,
  historicLabel?: string,
  historicExtras?: { [key: string]: any },
  historicMapper?: (data: Object[], extra?: any) => BaseEntity[]

}) => {
  const {
    indicator,
    title,
    position,
    size,
    helpText = '¿qué tan probable es que recomiendes a Profuturo a tus amigos y familiares?',
    extras,
    extraConfigs,
    historicGrouped,
    historicGroupByResponseValueId,
    historicLabel,
    historicExtras,
    historicMapper

  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: title ?? 'NPS Acumulado',
    key: 'nps-por-mes',
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extras: extras,
    extraConfigs: extraConfigs ? extraConfigs : {
      //colors: PRODUTURO_NPS_COLORS,
      categories: ['promoters', 'passives', 'detractors'],
      useEmojis: false
    },
    historic: {
      type: 'line',
      formula: 'nps-10',
      grouped: historicGrouped,
      groupByResponseValueId: historicGroupByResponseValueId,
      label: historicLabel,
      extras: historicExtras,
      mapper: historicMapper

    }
  })
}

export const getProfuturoSatisfaccion = (props: {
  indicator: string,
  position?: number[],
  size: number[],
  helpText: string,
  extras?: { [key: string]: any }
  extraConfigs?: { [key: string]: any },
  historicGrouped?: string,
  historicGroupByResponseValueId?: number,
  historicLabel?: string,
  historicExtras?: { [key: string]: any },
  mapper?: (data: Object[], extra?: any) => BaseEntity[]
  historicMapper? : (data: Object[], extra?: any) => BaseEntity[]
  historicFormula?: string
  historicFormulaConfig?: string
}) => {
  const {
    indicator,
    position,
    size,
    helpText,
    extras,
    extraConfigs,
    historicGrouped,
    historicGroupByResponseValueId,
    historicLabel,
    historicExtras,
    mapper,
    historicMapper,
    historicFormula,
    historicFormulaConfig
  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: 'Satisfacción',
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extras: extras,
    extraConfigs: extraConfigs,
    mapper: mapper,
    historic: {
      type: 'line',
      formula: historicFormula ?? 'wavg',
      formulaConfig: historicFormulaConfig ?? 'wavg-top',
      grouped: historicGrouped,
      groupByResponseValueId: historicGroupByResponseValueId,
      label: historicLabel,
      extras: historicExtras,
      mapper: historicMapper
      }
  })
}



export const getProfuturoAtencion = (props: {
  indicator: string,
  position?: number[],
  size: number[],
  helpText: string,
  extraConfigs?: { [key: string]: any }
}) => {
  const { indicator, position, size, helpText, extraConfigs } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: 'Atención',
    position: position,
    size: size,
    helpText: helpText,
    indicator: indicator,
    extraConfigs: extraConfigs,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top'
    }
  })
}

export const getProfuturoAsesoria = (props: {
  indicator: string,
  position: number[],
  size: number[],
  helpText: string
}) => {
  const { indicator, position, size, helpText } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: 'Asesoria',
    position: position,
    size: size,
    helpText: helpText,
    indicator: indicator,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top'
    }
  })
}

export const getProfuturoFacilidad = (props: {
  indicator: string,
  position?: number[],
  size: number[],
  title?: string,
  helpText: string,
  extras?: { [key: string]: any }
  extraConfigs?: { [key: string]: any }
  historicGrouped?: string,
  historicGroupByResponseValueId?: number,
  historicLabel?: string,
  historicExtras?: { [key: string]: any }

}) => {
  const {
    indicator,
    position,
    size,
    title = 'Facilidad',
    helpText,
    extras,
    historicGrouped,
    historicGroupByResponseValueId,
    historicLabel,
    historicExtras,
    extraConfigs
  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'countNumberSemicircle',
    title: title,
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extras: extras,
    extraConfigs: extraConfigs,
    historic: {
      type: 'line',
      formula: 'wavg',
      formulaConfig: 'wavg-top',
      grouped: historicGrouped,
      groupByResponseValueId: historicGroupByResponseValueId,
      label: historicLabel,
      extras: historicExtras,
    }
  })
}

export const getProfuturoNpsAcumulado = (props: {
  indicator: string,
  position?: number[],
  size: number[],
}) => {
  const { indicator, position, size } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'npsMultipleTrend',
    title: 'NPS por mes',
    key: 'nps-acumulado',
    position: position,
    size: size,
    indicator: indicator,
    extraConfigs: {
      decimals: 0,
      colors: PRODUTURO_SCALE_3_COLORS,
    }
  })
}

export const getProfuturoNpsAcumuladoByBimonthly = (props: {
  indicator: string,
  position?: number[],
  size: number[],
  extras?: { [key: string]: any },
}) => {
  const { indicator, position, size , extras} = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'multipleTrendByBimonthly',
    title: 'NPS por mes',
    key: 'nps-acumulado',
    position: position,
    mapper: (data: any): any => {
        const excludeGroups = ['-3', '-6'];
        const filteredData = data
            .filter(({ group }: any) => excludeGroups.some(exclude => group.includes(exclude)))
            .map(({ group, groupName, ...rest }: any) => {
                const replaceGroup = (str: string) => 
                    str.includes('-3') ? str.replace('-3', '-1') : str.replace('-6', '-2');

                return {
                    ...rest,
                    group: replaceGroup(group),
                    groupName: replaceGroup(groupName),
                };
            });
            return filteredData
            },
    size: size,
    indicator: indicator,
    extraConfigs: {
      decimals: 0,
      colors: PRODUTURO_SCALE_3_COLORS,
    },
    extras:{
      ...extras  
    }
  })
}

export const getNpsPorEjecutivoTable = (props: {
  title?: string,
  position?: number[],
  size: number[],
  criticalMomentInclude?: number[]
}) => {
  const {
    title = 'NPS por Ejecutivo',
    position,
    size,
    criticalMomentInclude = undefined
  } = props

  const indicators = ['NPS_GENERAL', 'CSAT_GENERAL', 'ATENCION_GENERAL', 'SURVEY_COUNT']

  return {
    title: title,
    helpLink: '--',
    size: size ?? [4, 2],
    position: position,
    config: {
      id: 'asesores-dashboard',
      type: 'dataTable',
      color: ['#CCC'],
      enableArcLabels: true,
      valueFormat: ' >-.0%',
      groupName: 'Ejecutivo',
      sorter: (a: any, b: any) => {
        const npsA = a?.[GENERAL_INDICATORS.NPS_GENERAL] ?? 0
        const npsB = b?.[GENERAL_INDICATORS.NPS_GENERAL] ?? 0
        const csatA = a?.[GENERAL_INDICATORS.CSAT_GENERAL] ?? 0
        const csatB = b?.[GENERAL_INDICATORS.CSAT_GENERAL] ?? 0
        const atA = a?.[GENERAL_INDICATORS.ATENCION_GENERAL] ?? 0
        const atB = b?.[GENERAL_INDICATORS.ATENCION_GENERAL] ?? 0

        return npsA !== npsB ? npsB - npsA : (csatA !== csatB ? csatB - csatA : atB - atA)
      },
      mapper: (data: any): any => {
        const values = data.map((item: any, idx: number) => {
          return indicators.map((ind: string, subIdx: number) => ({
            id: item?.id + '.' + subIdx,
            'group_1': item?.['GROUP'],
            'group_0': ind,
            groupName: GENERAL_INDICATORS[ind],
            value: item?.[ind]
          }))
        }).flat(1)
        //console.log(values)
        return values
      },
      indicators: [
        {
          indicator: 'NPS_GENERAL',
          url: 'reports/asesores-dashboard',
          label: 'group',
          value: 'value',
          singleton: true,
          keyExtract: ['GROUP', ...indicators],
          extras: {
            resultType: 'JSON',
            criticalMomentInclude: criticalMomentInclude
          }
        }
      ]
    }
  }
}

export const getProfuturoNpsAcumuladoFourMonth = (props: {
  indicator: string,
  position: number[],
  size: number[],
  extras?: { [key: string]: any },
}) => {
  const { indicator, position, size, extras } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'npsMultipleTrendFourMonthPeriod',
    title: 'NPS por Cuatrimestre',
    key: 'nps-acumulado-cuatrimestre',
    position: position,
    size: size,
    indicator: indicator,
    extras: {
      ...extras
    },
    extraConfigs: {
      decimals: 0
    }
  })
}

export const getProfuturoNpsConsar = (props: {
  indicator: string,
  position?: number[],
  size: number[],
  title?: string,
  helpText: string,
  extraConfigs?: { [key: string]: any }
}) => {
  const {
    indicator,
    position,
    size,
    title,
    helpText,
    extraConfigs
  } = props
  return getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'semicircleGeneric',
    title: title,
    key: 'nps-por-mes',
    position: position,
    size: size,
    indicator: indicator,
    helpText: helpText,
    extraConfigs: extraConfigs ? extraConfigs : {
      //colors: PRODUTURO_PIE_SINO_COLORS,
      categories: ['promoters', 'passives', 'detractors'],
      useEmojis: false
    },
    historic: {
      type: 'line',
      formula: 'nps-10'
    }
  })
}

const getCJIndicators = (indicatorsConfig: { [key: string]: any }) => {
  return Object.keys(indicatorsConfig).map(key => ({
    indicator: indicatorsConfig[key].name,
    grouped: undefined,
    keyExtract: ['group', 'value', 'groupId'],
    label: 'group',
    value: 'value',
    singleton: true,
    extras: {
      groupNameFormat: 'description',
      formula: indicatorsConfig[key].formula ?? undefined,
      ...indicatorsConfig[key].extrasFechas ?? undefined
    },
  }));
};



export const getProfuturoCustomerJourney = (props: {
  id: string
  indicators: { [key: string]: any },
  position?: number[],
  size: number[],
  title?: string,
  helpText?: string,
  extraConfigs?: { [key: string]: any }
}) => {
  const {
    id,
    indicators,
    position,
    size,
    title,
    helpText,
    extraConfigs
  } = props
  return {
    title: title,
    position: position,
    size: size,
    helpText: helpText ?? 'A excepción del NPS, las métricas mostradas son el TTB respuesta del cliente (porcentaje de menciones para las dos respuestas de mayor valor dentro de la escala)',
    showHelp: true,
    config: {
      id: id,
      type: 'multipleGauge',
      fillMode: '',
      maxValue: 100,
      colors: COLORS_CONFIG,
      showValues: true,
      showHistoric: false,
      mapper: (data: any, extra: any) => {
        console.log('rendering CJ profuturo!')
        console.log(data)
        console.log(indicators)
        return Object.keys(data).flatMap((key) => {
          const sortedValues = data[key]
            .asMutable()
            .sort((c1: any, c2: any) => c1.groupId - c2.groupId)
            .map((item: any) => {
              const label = indicators
                ?.find((ind: any) => ind.name === key)
                ?.label

              return {
                value: item.value,
                name: key,
                label: label
              }
            });

          const item = sortedValues?.[0]
          const item2 = sortedValues?.[1]
          return item
            ? {
              label: item.label ?? `widgets.indicatorId.${item.name}`,
              value: item2 ? item.value + item2.value : item.value,
              //value: item.value
            }
            : undefined;
        });

      },
      indicators: getCJIndicators(indicators)
    },
  }
}   
import { getCommonWidget } from '../../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, haWordCountWithLinksMapper  } from './../commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
  title: 'Unidad de Referencia',
  type: 'label',
  position: [1, 0],
  size: [3, 1],
  config: { color: '#B7AADC' },
    },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_14',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
     }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Unidad de referencia ',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: true,
    },
    indicator: 'accesos_vasculares_usuario_pregunta_1',
    criticalMomentInclude: [11655]
  }),
  getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'pieGenericSmall',
      title: 'Unidad que proporciona atención ',
      position: [0, 3],
      size: [3, 2],
      extraConfigs: {
        showSample: true,
      },
      indicator: 'accesos_vasculares_usuario_pregunta_19',
      criticalMomentInclude: [11655]
    }),
  {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 5],
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general con el servicio recibido.',
    position: [0, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_6',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Claridad de la información otorgada para su atención en el servicio',
    position: [1, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_7',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Puntualidad para el ingreso de su cita',
    position: [2, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_8',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Servicio y habilidades del personal de enfermería',
    position: [3, 6],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_9',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
    }
  }),
 {
    title: 'Evaluación General',
    type: 'label',
    position: [0, 8],
    size: [4, 1],
   },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'El trato del personal médico durante su procedimiento',
    position: [0, 9],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_10',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Claridad de la información posterior a su procedimiento',
    position: [1, 9],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'accesos_vasculares_usuario_pregunta_11',
    criticalMomentInclude: [11655],
    extraConfigs:{
        valueSymbol:''
    }
  }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'haTrendByGeo',
     title: 'Condiciones físicas y limpieza de las instalaciones',
     position: [2, 9],
     size: [1, 2],
     mapper: haTrendDataMapper,
     indicator: 'accesos_vasculares_usuario_pregunta_12',
     criticalMomentInclude: [11655],
     extraConfigs:{
         valueSymbol:''
     }
   }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'textAnalysis',
     position:[0,11], 
     size: [2, 3],
     indicator: 'SENTIMENT',
     criticalMomentInclude: [11655],
     extraConfigs:{
        hideBars: true
     }
   }),
  getCommonWidget({
     commonWidgets: commonWidgets,
     name: 'wordCountWithLinks',
     title: 'Nube de Palabras / Frecuencia en comentarios',
     position: [2, 11],
     size: [2, 3],
     mapper: haWordCountWithLinksMapper,
     criticalMomentInclude: [11655]
   }),
   
]

export default {
  title: 'Hospital Angeles Servicio de Accesos Vasculares',
  description: 'Hospital Angeles Servicio de Accesos Vasculares',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig

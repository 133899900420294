import { COLORS_CONFIG, DEFAULT_COLORS } from '@components/widgets/apacheEchart/Commons'
import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'



export const GREEN_1 = '#88DD76'
export const GREEN_2 = '#AEDD76'
export const ORANGE = '#F3AF5A'
export const BLUE = '#8CF0EA'
export const RED_2 = '#DB7476'
export const RED_1 = '#CD4245'
export const YELLOW = '#F1E713'

export const CJ_SUZUKI_ORDER = {
  106: 0,	// Explicación Trabajo
  1007: 1,	// Entrega
  1006: 2,	// Asesor servicio
  112: 3,	// Facturación
  1: 0,	// Bienvenida
  2: 1,	// Venta Consultiva
  3: 2,	// Prueba de Manejo
  5: 3,	// Financiamiento
  7: 4,	// Seguimiento
  8: 5,	// Entrega
}

export const suzukiCustomerJourneyMapper = (data: any, extra: any) => {
  return data.map((item: any) => {
    return {
      ...item,
      label: 'widgets.indicatorId.' + item.groupId,
      weight: CJ_SUZUKI_ORDER[item.groupId],
    }
  })// @ts-ignore
    .asMutable()
    .sort((item1: any, item2: any) => item1.weight - item2.weight)
}

export const suzukiCustomPieMapper = (data: any) => {
  return data.map((item: any) => ({
    id: item["group"] == null ? "Indefinido" : item["group"],
    label: item["group"] == null ? "Indefinido" : item["group"],
    value: item["value"],
  }))
}

export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const SUZUKI_COLORS_CONFIG = [
  {
    color: DEFAULT_COLORS[1],
    condition: '<=' as const,
    key: 'value',
    value: 3,
  },
  {
    color: DEFAULT_COLORS[2],
    condition: '>' as const,
    key: 'value',
    value: 3,
  },
  {
    color: DEFAULT_COLORS[0],
    condition: '>=' as const,
    key: 'value',
    value: 4,
  },
]

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
  multipleGaugeByQuestion: {
    ...widgetsLibrary.multipleGaugeByQuestion,
    //@ts-ignore
    config: {
      ...widgetsLibrary.multipleGaugeByQuestion.config,
      colors: SUZUKI_COLORS_CONFIG,
      maxValue: 5
    },
  }
}

export const SUZUKI_SCALE_3_COLORS = {
  'promoters': GREEN_1,
  'passives': ORANGE,
  'detractors': RED_2,
  'positive': GREEN_1,
  'neutral': ORANGE,
  'negative': RED_1,
  1: GREEN_1,
  2: ORANGE,
  3: RED_1,
}

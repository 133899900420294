import { getChildrens, getCriticalMomentsValues, getLeaves, selectedCriticalMoments } from "@utils/CriticalMomentUtils"
import { selectedGeos } from "@utils/GeoUtils"
import _, { isArray, isNumber, isString } from "lodash"
import Immutable from 'seamless-immutable'
import useClientConfig from "../useClientConfig"
import { DEFAULT_COLORS, DEFAULT_SCALE_3_COLORS } from "@components/widgets/apacheEchart/Commons"
import { color, registerPostInit } from "echarts"

export const answeredMapper = (data: any): any => {
  //console.log('Answered mapper')
  //console.log(data)
  let answered: any = data !== undefined && data.find((item: any) => item['group'] == 'ANSWERED')
  answered = answered && answered['count'] !== undefined ? answered['count'] : 0

  let inProgress: any = data !== undefined && data.find((item: any) => item['group'] == 'IN_PROGRESS')
  inProgress =
    inProgress && inProgress['count'] !== undefined ? inProgress['count'] : 0
  const total = answered + inProgress
  //return inProgress > 0 ? { value: (answered / inProgress) * 100 } : []
  return total > 0 ? { value: (answered / total) * 100, total: total } : []
}

export const porcRespuestasMapper = (data: any): any => {
  return data !== undefined
    && data[0] !== undefined
    && data[0].resume !== undefined
    ? { value: data[0].resume?.answeredPerc }
    : {}
}

export const calidadDatosMapper = (data: any): any => {
  return data !== undefined
    && data[0] !== undefined
    && data[0].resume !== undefined
    ? { value: data[0].resume?.sentPerc }
    : {}
}

export const invalidMapper = (data: any): any => {
  //console.log('Invalid mapper')
  //console.log(data)
  const total = data !== undefined && data
    .map((item: any) => item['count'])
    .reduce((prev: any, curr: any) => prev + curr, 0)

  let invalid: any = data !== undefined
    && data.find((item: any) => item['group'] == 'INVALID')

  invalid = invalid && invalid['count'] !== undefined ? invalid['count'] : 0
  //return { value: total > 0 ? ((total - invalid) / total) * 100 : 0 }
  return total > 0 ? { value: ((total - invalid) / total) * 100, total: total } : []
}

export const yesNoMapper = (data: any) => {
  let yesCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'SI')
  yesCounter = yesCounter && yesCounter['count'] !== undefined ? yesCounter['count'] : 0

  let noCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'NO')
  noCounter = noCounter && noCounter['count'] !== undefined ? noCounter['count'] : 0

  const totalCounter = yesCounter + noCounter

  let result: any[] = []

  if (noCounter > 0) {
    result = [
      {
        id: 0,
        group: 'NO',
        label: 'No',
        value: noCounter > 0 ? (noCounter / totalCounter) * 100 : 0
      }
    ]
  }

  if (yesCounter > 0) {
    result = [
      ...result,
      {
        id: 1,
        group: 'SI',
        label: 'Si',
        value: yesCounter > 0 ? (yesCounter / totalCounter) * 100 : 0
      }
    ]
  }

  //return { value: noCounter > 0 ? (yesCounter / noCounter) * 100 : 100 }
  return result
}
//*TODO: refactorizar con yesNoMapper
export const genderMapper = (data: any) => {
  let maleCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'MASCULINO')
  maleCounter = maleCounter && maleCounter['count'] !== undefined ? maleCounter['count'] : 0

  let femaleCounter: any = data.find((item: any) => item['group'] !== undefined
    && item['group'].toUpperCase() === 'FEMENINO')
  femaleCounter = femaleCounter && femaleCounter['count'] !== undefined ? femaleCounter['count'] : 0

  const totalCounter = maleCounter + femaleCounter

  let result: any[] = []

  if (femaleCounter > 0) {
    result = [
      {
        id: 0,
        group: 'FEMENINO',
        label: 'Femenino',
        value: femaleCounter > 0 ? (femaleCounter / totalCounter) * 100 : 0
      }
    ]
  }

  if (maleCounter > 0) {
    result = [
      ...result,
      {
        id: 1,
        group: 'MASCULINO',
        label: 'Masculino',
        value: maleCounter > 0 ? (maleCounter / totalCounter) * 100 : 0
      }
    ]
  }

  //return { value: noCounter > 0 ? (yesCounter / noCounter) * 100 : 100 }
  return result
}





export const inversePorcMapper = (data: any) => {
  return { value: 100 - data['value'] }
}

export const groupMapper = (data: any) => {
  return data.map((item: any) => ({
    id: item['group'] == null ? 'Indefinido' : item['group'],
    label: item['group'] == null ? 'Indefinido' : item['group'],
    value: item['value'],
    count: item.count
  }))
}

export const groupMapperLateral = (data: any) => {
  return data.map((item: any) => ({
    id: item['group'] == null ? 'Indefinido' : item['group'],
    label:
      item['group'] == null
        ? 'Indefinido'
        : item['group'].substr(0, 35).trim() + '.',
    value: item['value'],
    count: item.count
  }))
}

export const pieColors = [
  '#019E85',
  '#88DD76',
  '#DEE13C',
  '#DB7476',
  '#3f3166',
  '#c878e3',
  '#019E85',
  '#88DD76',
  '#DEE13C',
  '#DB7476',
  '#3f3166',
  '#c878e3',
]



export const grayColors = [
  '#D5DBDB',
  '#E8E8E8',
  '#D8D8D8',
  '#D3D3D3',
  '#C0C0C0',
  '#B0B0B0',
  '#A0A0A0',
  '#888888',
  '#686868',
  '#606060'
]

export const getFilterCriticalMoments = (filtersData: Record<string, any>, userProfiles: UserProfile[], selectType: String): CriticalMoment[] => {
  /*const criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].children
    ? filtersData.criticalMoments[0].children
    : filtersData.criticalMoments*/

  if (selectType === 'tree') {
    return filtersData.criticalMoments;
  }

  const { getCriticalMomentsRootId } = useClientConfig()

  const generalId = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].id
    ? filtersData.criticalMoments[0].id
    : getCriticalMomentsRootId()

  const general = {
    id: generalId,
    name: 'Todos',
    description: 'Todos'
  }

  //const cms = criticalMoments.filter((cm: any) => cm.name !== 'TODOS')
  const cms = getChildrens(filtersData.criticalMoments)

  //console.log('Get filter critical moments data')
  //console.log(cms)

  const sortedCms = cms
    ?.sort((a: any, b: any) => a.description > b.description
      ? 1 : (a.description < b.description ? -1 : 0))


  // @ts-ignore
  return sortedCms?.length > 1 ? [general, ...sortedCms,] : sortedCms

}



export const getFilterCriticalMomentDefault = (filtersData: Record<string, any>): number => {
  const criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].id
    ? filtersData.criticalMoments[0].id
    : 0

  return criticalMoments
}

/************************************************************************************
 *  BUPA
 ************************************************************************************/

export const getBupaFilterCriticalMoments = (filtersData: Record<string, any>, userProfiles: UserProfile[], selectType: String): CriticalMoment[] => {
  // Función recursiva para filtrar la estructura jerárquica
  const filterRecursive = (nodes: any[]): any[] => {
    return nodes
      .filter((node) => node.id !== 23053 && node.id !== 25301) // Excluye nodos con los ID específicos
      .map((node) => ({
        ...node,
        // Si el nodo tiene hijos, filtra recursivamente
        children: node.children ? filterRecursive(node.children) : []
      }));
  };

  // Aplicar el filtro recursivo a criticalMoments
  return filterRecursive(filtersData.criticalMoments || []);
};

/************************************************************************************
 *  PROFUTURO
 ************************************************************************************/

export const getProfuturoFilterCriticalMoments = (filtersData: Record<string, any>, userProfiles: UserProfile[], selectType: String): CriticalMoment[] => {
  if (selectType === 'tree') {
    return filtersData.criticalMoments;
  }

  const { getCriticalMomentsRootId } = useClientConfig()

  const general = {
    id: getCriticalMomentsRootId(),
    name: 'Todos',
    description: 'Todos'
  }

  const cms = getChildrens(filtersData.criticalMoments)

  const sortedCms = cms
    ?.filter((cm: any) => cm?.id !== 10367 && cm?.id !== 11251)
    ?.sort((a: any, b: any) => a.description > b.description
      ? 1 : (a.description < b.description ? -1 : 0))

  // @ts-ignore
  return [general, ...sortedCms,]

}


export const getProfuturoFilterCriticalMomentDefault = (filtersData: Record<string, any>): number => {
  const { getCriticalMomentsRootId } = useClientConfig()

  return getCriticalMomentsRootId()
}

/************************************************************************************
 *  MITUSBISHI
 ************************************************************************************/


export const getMitsubishiFilterCriticalMoments = (
  filtersData: Record<string, any>,
  userProfiles: UserProfile[]
): CriticalMoment[] => {

  const profile = userProfiles
    && userProfiles
    && userProfiles[0]
    && userProfiles[0].name
    ? userProfiles[0].name.toLowerCase() : undefined

  let criticalMoments = filtersData.criticalMoments
    && filtersData.criticalMoments[0]
    && filtersData.criticalMoments[0].children
    ? filtersData.criticalMoments[0].children
    : filtersData.criticalMoments

  if (profile && ['cxo', 'demo', 'omoikane'].includes(profile)) {
    const general = {
      id: 0,
      name: 'General',
      description: 'General'
    }
    criticalMoments = [
      general,
      ...criticalMoments,
    ]
  }

  return criticalMoments
}

export const getMitsubishiFilterCriticalMomentDefault = (filtersData: Record<string, any>, userProfiles: UserProfile[]): number => {
  const profile = userProfiles
    && userProfiles
    && userProfiles[0]
    && userProfiles[0].name
    ? userProfiles[0].name.toLowerCase() : undefined

  return profile && ['cxo', 'demo', 'omoikane'].includes(profile) ? 0 : 1
}

export const renderForCriticalMoment = (filters: FiltersUI, cmIncludes: number[]) => {
  return (
    filters !== undefined &&
    filters.criticalMoments !== undefined &&
    filters.criticalMoments !== null &&
    filters.criticalMoments.some(cm => cmIncludes.includes(cm))
  )
}


export const renderForGeoLocation = (filters: FiltersUI, geoIncludes: number[]) => {
  return (
    filters !== undefined &&
    filters.geographicDistributions !== undefined &&
    filters.geographicDistributions !== null &&
    filters.geographicDistributions.some(geo => geoIncludes.includes(geo))
  )
}

export const isGeoLocationLeaf = (filters: FiltersUI, filtersData: Record<string, any>) => {
  //console.log('isCriticalMomentLeaf!!!!')
  const geoIncludes = filters?.geographicDistributions
  //console.log(geoIncludes)
  const geos = selectedGeos(filtersData?.geographicDistributions, geoIncludes)
  //console.log(geos)
  //console.log(geoIncludes)
  return (
    geos !== undefined &&
    geos.length === 1 &&
    geos[0] !== undefined &&
    geos[0].children.length === 0
  )
}

export const isRegionGeoLocation = (filters: FiltersUI, filtersData: Record<string, any>, region: number[]) => {
  //console.log(`isRegionGeoLocation ${region}`)
  const geoIncludes = filters?.geographicDistributions
  const geos = selectedGeos(filtersData?.geographicDistributions, geoIncludes)
  ///console.log(geos)
  //console.log(geoIncludes)

  // solo selecciono la region
  const onlyRegion = geos !== undefined
    && geos.length === 1
    && geos[0] !== undefined
    && (region.includes(geos[0]?.id)
      || (geos[0]?.parentId !== undefined
        && geos[0]?.parentId !== null
        && region.includes(geos[0]?.parentId)))

  const allRegion = geos !== undefined
    && geos.length >= 1
    && geos.reduce((acc: boolean, curr: any) => acc
      && curr?.parentId !== undefined
      && curr?.parentId !== null
      && region.includes(curr?.parentId), true)

  //console.log(`onlyRegion: ${onlyRegion}`)
  //console.log(`allRegion: ${allRegion}`)

  return onlyRegion || allRegion
}

const getBackWidgetConfig = (wdg: Widget): WidgetConfig => {
  const { getDecimals } = useClientConfig()
  const defaultIndicators = wdg.config?.indicators?.map(ind => ({
    ...ind,
    indicator: ind.indicator ?? ind.key,
    //grouped: 'formattedMonth',
    keyExtract: ['passives', 'detractors', 'promoters',
      'passiveCount', 'detractorCount', 'promoterCount',
      'value', 'groupName', 'count'],
    label: 'group',
    value: 'value',
    singleton: false,
    historic: false,
    extras: {
      ...ind.extras,
      //periods: 6
    },
  }))

  const defaultConfigs = {
    ...wdg.config,
    id: wdg.config?.id ?? 'default-id-' + wdg.title,
    showHistoric: false,
    type: 'trend',
    decimals: getDecimals(),
    indicators: defaultIndicators
  }

  if (wdg.config?.type === 'nps') {
    return {
      ...defaultConfigs,
      type: 'semicircle'
    }
  }

  return defaultConfigs
}

export const createBackendWidgets = (props: {
  backDashboards?: Record<string, DasboardConfig>,
  staticDashboards?: Record<string, DasboardConfig>,
  filters: FiltersUI,
}): Widget[] => {

  const { filters, backDashboards, staticDashboards } = props

  //console.log('config back dashboards!!!!')
  //console.log(props)

  let newBacks = {}
  backDashboards !== undefined
    && Object.keys(backDashboards).forEach((key: string) => {
      const dash = backDashboards[key]
      newBacks[key] = {
        ...dash,
        dashType: 'back'
      }
    })

  let newStatics = {}
  staticDashboards !== undefined
    && Object.keys(staticDashboards).forEach((key: string) => {
      const dash = staticDashboards[key]
      newStatics[key] = {
        ...dash,
        dashType: 'static'
      }
    })

  const allDashboards = {
    ...newBacks,
    ...newStatics
  }

  if (allDashboards === undefined || allDashboards === null || Object.keys(allDashboards).length === 0) {
    return []
  }

  return Object.keys(allDashboards)
    .filter(key => allDashboards[key].widgets.length > 0)
    .map((key: string) => {
      const dash = allDashboards[key]
      const criticalMomentId = Number(key)
      let widgets: Widget[] = []

      //console.log('Config all dashboards para ')
      //console.log(dash)

      if (isNumber(criticalMomentId)) {
        const dashWidgets = dash.dashType === 'back'
          ? dash.widgets
          : dash.widgets(filters)

        widgets = [
          ...dashWidgets
            .map((wdg: any) => {
              const widgetConfig = dash.dashType === 'back'
                ? getBackWidgetConfig(wdg)
                : wdg.config
              return {
                ...wdg,
                config: widgetConfig,
                render: (filters: FiltersUI) => {
                  //NUEVO: Si viene un render desde el widget, usamos ese, sino usamos el default de momento critico  
                  return (wdg.render ?? renderForCriticalMoment)(filters, [criticalMomentId]);
                }
              }
            }),
        ]
      }

      return widgets
    })
    .filter((item: any) => (item !== undefined || _.isEmpty(item)))
    .flat(1)
}

export const createDynamicWidgets = (props: {
  dynamicDashboards?: Record<string, any>,
  filters: FiltersUI,
}): Widget[] => {
  const { getDecimals } = useClientConfig()

  const { filters, dynamicDashboards } = props

  const dashboards: Record<string, Widget[]> = dynamicDashboards !== undefined
    ? Object.keys(dynamicDashboards)
      .reduce((acc: any, key: string) => {
        //const criticalMomentId = Number(key)
        const criticalMomentId = Number(key)
        let widgets: Widget[] = []

        if (dynamicDashboards[criticalMomentId] !== undefined
          && dynamicDashboards[criticalMomentId].length > 0
        ) {
          const dashWidgets = dynamicDashboards[criticalMomentId]

          console.log('Config all dashboards para ')
          console.log(dashWidgets)

          widgets = [
            ...dashWidgets
              .filter((wdg: any) => wdg.widgetName !== null && wdg.widgetName !== 'text')
              //.filter((wdg: any) => wdg.name === 'cuarto_contacto_pref_comment')
              //.filter((wdg: any) => wdg.widgetName === 'comment')
              .map((wdg: any) => {

                // Widget type | formula | singleton
                let widgetType = 'tren'
                let formula = wdg.formula
                let singleton = false
                let groupNameFormat = undefined
                let model = undefined
                let radius = undefined
                let center = undefined
                let fontSize = 25
                let itemHeight = 20
                let itemWidth = undefined
                let colors: WidgetColorConfig[] | string[] | string | Object = DEFAULT_COLORS

                let height = 2
                let width = 1

                if (wdg.widgetName === 'comment') {
                  formula = formula === null ? 'word-count' : formula
                  widgetType = formula === 'word-count' ? 'wordCloud' : 'comments'
                  singleton = true
                  width = 2
                } else if (wdg.widgetName === 'rating') {
                  widgetType = 'semicircle'
                  formula = formula === null ? 'wavg-5' : formula
                  //formula = 'nps-5'
                  singleton = false
                  groupNameFormat = 'description'
                  model = 'avg'
                  //radius = [35, 80]
                  //center = ['50%', '43%']
                  center = ['50%', '65%'],
                    radius = ['55%', '95%'],
                    itemHeight = 10
                  width = 2
                  colors = DEFAULT_SCALE_3_COLORS
                } else if (wdg.widgetName === 'radiogroup' || wdg.widgetName === 'imagepicker') {
                  widgetType = 'pie'
                  formula = formula === null ? 'count-number' : formula
                  singleton = true
                  radius = [40, 85]
                  center = ['40%', '55%']
                  itemWidth = 80
                  width = 2
                  colors = pieColors
                }

                // Size

                const widgetId = `widget-${wdg.id}-${wdg.widgetName}`

                return {
                  title: wdg.description,
                  showHelp: true,
                  helpText: wdg.description,
                  size: [width, height],
                  order: wdg.order,
                  config: {
                    id: widgetId,
                    showHistoric: false,
                    type: widgetType,
                    decimals: getDecimals(),

                    // Pie config
                    colors: colors,
                    showValues: true,
                    showSample: false,
                    itemWidth: itemWidth,
                    itemHeight: itemHeight,
                    //arcLabelsRadiusOffset: 1.15,
                    itemDirection: 'left-to-right',
                    anchor: 'top-left',
                    direction: 'column',
                    //marginLeft: 250,
                    //legendTranslateX: -200,
                    radius: radius,
                    center: center,
                    fontSize: fontSize,

                    /*mapper: (data: any): any => {
                      console.log(`data mapper ${widgetId}`)
                      console.log(data)
                      return data
                    },*/

                    indicators: [{
                      indicator: wdg.name,
                      //grouped: 'formattedMonth',
                      keyExtract: [
                        'passives',
                        'detractors',
                        'promoters',
                        'passiveCount',
                        'detractorCount',
                        'promoterCount',
                        'value',
                        'group',
                        'groupName',
                        'count',
                        'comment',
                        'createdAt',
                        'criticalMoment',
                        'groupId'
                      ],
                      label: 'group',
                      value: 'value',
                      singleton: singleton,
                      historic: false,
                      extras: {
                        formula: formula,
                        groupNameFormat: groupNameFormat,
                        model: model
                      },
                    }]
                  },
                  render: (filters: FiltersUI) => {
                    //NUEVO: Si viene un render desde el widget, usamos ese, sino usamos el default de momento critico  
                    return (wdg.render ?? renderForCriticalMoment)(filters, [criticalMomentId]);
                  }
                }
              }),
          ]
        }

        return {
          ...acc,
          [criticalMomentId]: widgets
        }
      }, {})
    : []


  //console.log('config dynamic dashboards!!!!')
  //console.log(Object.values(dashboards).flat(1))

  return Object.values(dashboards)?.flat(1) ?? []
}

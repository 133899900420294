import { getCommonWidget } from '../../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, haWordCountWithLinksMapper } from './../commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Unidad de Referencia',
    type: 'label',
    size: [3, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_17',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: 'Unidad de referencia',
    size: [3, 2],
    extraConfigs: {
      showSample: true,
      valueSymbol: ''
    },
    indicator: 'convenios_subrogado_hemodialisis_pregunta_6',
    criticalMomentInclude: [12052]
  }),
  {
    title: 'Evaluación General',
    type: 'label',
    size: [4, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general con el servicio recibido.',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_9',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Comunicación efectiva para la mejora del servicio',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_10',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Calidad de las instalaciones y suficiencia de equipos de la Unidad de Hemodiálisis',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_11',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Seguimiento a las evaluaciones de servicio previas',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_12',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  {
    title: 'Evaluación General',
    type: 'label',
    size: [4, 1],
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Habilidades y conocimientos del personal Médico',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_13',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Habilidades y conocimientos del personal de Enfermería',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_14',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Habilidades y conocimientos del personal de Atención a Pacientes',
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'convenios_subrogado_hemodialisis_pregunta_15',
    criticalMomentInclude: [12052],
    extraConfigs: {
      valueSymbol: ''
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    size: [2, 3],
    indicator: 'SENTIMENT',
    criticalMomentInclude: [12052],
    extraConfigs: {
      hideBars: true
    }
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    size: [2, 3],
    mapper: haWordCountWithLinksMapper,
    criticalMomentInclude: [12052]
  }),

]

export default {
  title: 'Hospital Angeles Convenios Servicio Subrogado de Hemodiálisis',
  description: 'Hospital Angeles Convenios Servicio Subrogado de Hemodiálisis',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig

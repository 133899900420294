import { isGeoLocationLeaf, isRegionGeoLocation, renderForCriticalMoment, renderForGeoLocation } from '../../dashHelper'

// Defaults
import defaultCommonWidgets from './default'
import defaultHospitalesWidgets from './defaultHospitales'
import defaultCediasaWidgets from './defaultCediasa'
import defaultLaboratoriosWidgets from './defaultLaboratorios'
import defaultHospitalesLeafWidgets from './defaultHospitalesLeaf'

// Dashboards
import durante from './durante'
import posterior from './posterior'
import posterior2 from './posterior2'
import laboratorioServiciosSubrogados from './laboratorioServiciosSubrogados'
import serviciosAccesosVasculares from './serviciosAccesosVasculares'
import conveniosSubrogadosHemodialisis from './conveniosSubrogadosHemodialisis'
import laboratoriosMedicosSolicitantes from './laboratoriosMedicosSolicitantes'
import serviciosExternosDiagnostico from './serviciosExternosDiagnostico'
import servicioHemodialisisAmbulatoria from './servicioHemodialisisAmbulatoria'
import useClientConfig from 'config/clients/useClientConfig'
import { getHaGeoId } from '../commonWidgetsHa'

export default {
    title: 'Demo Dashboard',
    description: 'Dashboard de perfil Demo',
    backgroundClassName: (filters) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI, filtersData: Record<string, any>) => {
        const { getCriticalMomentsRootId, getGeosRootId } = useClientConfig()
        const cmRootId = getCriticalMomentsRootId()
        const geosRootId = getGeosRootId()

        console.log('RENDERING WORKFLOWS BY GEO!!!!')
        console.log(filtersData)
        console.log(isRegionGeoLocation(filters, filtersData, [12651]))

        return [
            ...defaultHospitalesWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => {
                        const hostMetroId = getHaGeoId('hospitales-metro')
                        const hostForanId = getHaGeoId('hospitales-for')
                        return renderForCriticalMoment(filters, [cmRootId, 0])
                            && (renderForGeoLocation(filters, [geosRootId, 0])
                                || isRegionGeoLocation(filters, filtersData, [hostMetroId, hostForanId]))
                    },
                })),
            ...defaultHospitalesLeafWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => {
                        const hostMetroId = getHaGeoId('hospitales-metro')
                        const hostForanId = getHaGeoId('hospitales-for')
                        return renderForCriticalMoment(filters, [cmRootId, 0])
                            && isGeoLocationLeaf(filters, filtersData)
                            && isRegionGeoLocation(filters, filtersData, [hostMetroId, hostForanId])
                    }
                    ,
                })),
            ...defaultCediasaWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => renderForCriticalMoment(filters, [cmRootId, 0])
                        && (renderForGeoLocation(filters, [geosRootId, 0])
                            || isRegionGeoLocation(filters, filtersData, [getHaGeoId('cediasa')])),
                })),
            ...defaultLaboratoriosWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => renderForCriticalMoment(filters, [cmRootId, 0])
                        && (renderForGeoLocation(filters, [geosRootId, 0])
                            || isRegionGeoLocation(filters, filtersData, [getHaGeoId('cediasa')])),
                })),
            ...defaultCommonWidgets.widgets(filters)
                .map((wdg) => ({
                    ...wdg,
                    render: (filters: FiltersUI) => renderForCriticalMoment(filters, [cmRootId, 0])
                        && !isGeoLocationLeaf(filters, filtersData),
                })),
            ...durante.widgets(filters)
                .map((wdg) => {
                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [9951]),
                    })
                }),
            ...posterior.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [10301]),
                    })
                }),
            ...posterior2.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11301]),
                    })
                }),
            ...laboratorioServiciosSubrogados.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11656]),
                    })
                }),
            ...serviciosAccesosVasculares.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11655]),
                    })
                }),
            ...conveniosSubrogadosHemodialisis.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12052]),
                    })
                }),
            ...laboratoriosMedicosSolicitantes.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11652]),
                    })
                }),
            ...serviciosExternosDiagnostico.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [12051]),
                    })
                }),
            ...servicioHemodialisisAmbulatoria.widgets(filters)
                .map((wdg) => {

                    return ({
                        ...wdg,
                        render: (filters: FiltersUI) => renderForCriticalMoment(filters, [11653]),
                    })
                }),

        ]
    },
} as DasboardConfig

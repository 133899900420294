import dayjs from 'dayjs'
import _ from 'lodash'
import { getCriticalMomentFields, getFilterField, getGeoLocationFields, getWorkflowNames } from './ReferenceUtils'
import useClientConfig from 'config/clients/useClientConfig'

/**
 * Parse the filters names and formats.
 * @param {Object} filters
 * @returns {Object}
 */
export default (filters: FiltersUI, filtersData: Record<string, any>, extras: any): Filters => {
  //console.log('Parsing lcm filters indicator ')
  //console.log(filtersData)
  //console.log(extras)

  const {
    getDateFromParamName,
    getDateToParamName
  } = useClientConfig()

  const dates = {
    [getDateFromParamName()]: dayjs(filters.fromDate).format('YYYY-MM-DD'),
    [getDateToParamName()]: dayjs(filters.toDate).format('YYYY-MM-DD'),
  }

  const geoCodes = getGeoLocationFields(filtersData.geographicDistributions,
    filters.geographicDistributions, 'name')
  const geoLeaves = getFilterField(filtersData.geographicDistributions,
    filters.geographicDistributions, 'name')
  const cms = getCriticalMomentFields(filtersData.criticalMoments,
    filters.criticalMoments, 'id')
  const workflowNames = [
    ...getWorkflowNames(cms, filtersData.workflows),
    extras.workflowName
  ]

  const geos = [...geoCodes, ...geoLeaves]

  const finalFilters = {
    ...dates,
    //...filters,
    workflowName: workflowNames && workflowNames.length > 0 ? workflowNames[0] : undefined,
    geoLocations: geos && geos.length > 0 ? geos.join(',') : undefined,
  }

  if (extras !== undefined && extras?.parseFilters !== undefined) {
    return extras?.parseFilters(finalFilters)
  }

  return finalFilters
}

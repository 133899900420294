import { getCommonWidget } from '../../dashWidgetHelper'
import { commonWidgets, haTrendDataMapper, yesNoMapperHa } from './../commonWidgetsHa'

const allWidgets = [
  {
    title: 'NPS',
    type: 'label',
    position: [0, 0],
    size: [1, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Ingreso',
    type: 'label',
    position: [1, 0],
    size: [3, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'NPS',
    position: [0, 1],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_5',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿Por qué seleccionó nuestro hospital?',
    position: [1, 1],
    size: [3, 2],
    extraConfigs: {
      showSample: false
    },
    indicator: 'posterior_a_pregunta_3',
    criticalMomentInclude: [10301]
  }),
  {
    title: 'Ingreso',
    type: 'label',
    position: [0, 3],
    size: [2, 1],
    config: { color: '#B7AADC' },
  },
  {
    title: 'Instalaciones',
    type: 'label',
    position: [2, 3],
    size: [2, 1],
    config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Amabilidad del personal durante el proceso  de admisión',
    position: [0, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_7',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Tiempo de espera en el proceso de admisión',
    position: [1, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_9',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Funcionalidad de las instalaciones',
    position: [2, 4],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_12',
    criticalMomentInclude: [10301]
  }),
  {
    title: 'Servicios Alimenticios',
    type: 'label',
    position: [0, 6],
    size: [4, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Amabilidad del personal de teléfono',
    position: [0, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_15',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Agrado de los alimentos',
    position: [1, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_17',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención del personal durante el abastecimiento de alimentos',
    position: [2, 7],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_19',
    criticalMomentInclude: [10301]
  }),
  {
    title: 'Atención personal de enfermería',
    type: 'label',
    position: [0, 9],
    size: [4, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿El personal de enfermería utilizó un lenguaje fácil de entender?',
    position: [0, 10],
    size: [2, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'posterior_a_pregunta_22',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Atención oportuna, botón de llamado',
    position: [2, 10],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_24',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción del trato recibido del personal de enfermería',
    position: [3, 10],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_26',
    criticalMomentInclude: [10301]
  }),
  {
    title: 'Dolor',
    type: 'label',
    position: [0, 12],
    size: [1, 1],
    //config: { color: '#B7AADC' },
  },
  {
    title: 'Atención personal médico',
    type: 'label',
    position: [1, 12],
    size: [3, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Control de dolor y seguimiento',
    position: [0, 13],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_29',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿El personal médico utilizó un lenguaje fácil de entender?',
    position: [1, 13],
    size: [2, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'posterior_a_pregunta_32'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Control de dolor y seguimiento',
    position: [3, 13],
    size: [1, 2],
    mapper: haTrendDataMapper,
    extraConfigs: {
      showSample: false
    },
    indicator: 'posterior_a_pregunta_34',
    criticalMomentInclude: [10301]
  }),
  {
    title: 'Atención General',
    type: 'label',
    position: [0, 15],
    size: [2, 1],
    //config: { color: '#B7AADC' },
  },
  {
    title: 'Alta',
    type: 'label',
    position: [2, 15],
    size: [2, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Resolución de inquietudes durante la hospitalización',
    position: [0, 16],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_37',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Amabilidad de los colaboradores durante la estancia',
    position: [1, 16],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_39',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Claridad de la información brindada a la salida',
    position: [2, 16],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_42',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Proceso de alta en general',
    position: [3, 16],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_44',
    criticalMomentInclude: [10301]
  }),
  {
    title: 'Seguros',
    type: 'label',
    position: [0, 18],
    size: [3, 1],
    //config: { color: '#B7AADC' },
  },
  {
    title: 'Experiencia General',
    type: 'label',
    position: [3, 18],
    size: [1, 1],
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'pieGenericSmall',
    title: '¿Contó con una compañía de seguros?',
    position: [0, 19],
    size: [1, 2],
    mapper: yesNoMapperHa,
    extraConfigs: {
      showSample: false,
      colors: ['#8CF0EA', '#DB7476']
    },
    indicator: 'posterior_a_pregunta_47',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Orientación de la compañía de seguros',
    position: [1, 19],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_49',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Rapidez de la compañía de seguros',
    position: [2, 19],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_51',
    criticalMomentInclude: [10301]
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haTrendByGeo',
    title: 'Satisfacción general durante su estancia',
    position: [3, 19],
    size: [1, 2],
    mapper: haTrendDataMapper,
    indicator: 'posterior_a_pregunta_54',
    criticalMomentInclude: [10301]
  }),
]

export default {
  title: 'Durante Dashboard',
  description: 'Dashboard de perfil Durante',
  widgets: (filters: FiltersUI) => {
    return allWidgets
  },
} as DasboardConfig

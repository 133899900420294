import { getCommonWidget } from "../dashWidgetHelper"
import { commonWidgets, getCmID, SUZUKI_SCALE_3_COLORS } from "./commonWidgetsSuzuki"
export default {
    title: "Distribuidor Dashboard",
    description: "Dashboard de Distribuidor",
    backgroundClassName: (filters: any) => {
        return 'epsilon-hero-bg'
    },
    widgets: (filters: FiltersUI) => [
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'lineDetailedByMonthAndGeo',
            title: 'Satisfacción General',
            position: [0, 0],
            size: [4, 3],
            indicator: 'POSVENTA_SATISFACCION',
            criticalMomentId: getCmID("servicio"),
            extraConfigs: {
                backgroundColor: 'transparent',
                theme: 'dark',
            },
            /*
            extras: {
                    groupByLevel: 3,
                    }
            
            */
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.snPostventa',
            position: [0, 4],
            size: [1, 2],
            indicator: 'POSVENTA_SATISFACCION',
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'multipleTrendByMonth',
            title: 'widgets.npsPostventa',
            position: [1, 4],
            size: [1, 2],
            indicator: 'POSVENTA_NPS',
            criticalMomentId: getCmID("servicio"),
            extras: {
                periods: 6
            },
            extraConfigs: {
                colors: SUZUKI_SCALE_3_COLORS
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'multipleGaugeByQuestion',
            title: 'widgets.customerJourneyPostventa',
            mapper: (data: any, extra: any) => {
                return data.map((item: any) => {
                    return {
                        ...item,
                        label: 'widgets.indicatorId.' + item.groupId
                    }
                })
            },
            indicator: 'POSVENTA_CUSTOMER_JOURNEY',
            position: [0, 6],
            size: [4, 2],
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'widgets.sentimentAnalysis',
            position: [0, 8],
            size: [4, 4],
            indicator: 'SENTIMENT',
            criticalMomentId: getCmID("servicio"),
        }),
        {
            title: "widgets.rankingZoneDistribuidores",
            position: [0, 12],
            size: [2, 1],
            config: {
                id: "posventa-ranking-zonas",
                type: "rankingIPN",
                /*mapper: (data: any) => {
                  return data.filter((item: any) => item.id == 1)
                },*/
                indicators: [
                    {
                        indicator: "POSVENTA_SATISFACCION",
                        keyExtract: ["group", "position", "groupCount"],
                        grouped: "geoLocation",
                        label: "group",
                        value: "position",
                        singleton: true,
                        sort: { value: "desc" },
                        //limit: 1,
                        extras: {
                            aggregation: "rankingPosition",
                            rankingPositionScope: 0,
                            //featureInclude: ['zoomOut'],
                            criticalMomentId: getCmID("servicio"),
                        },
                    },
                ],
            },
        },
        {
            title: "widgets.rankingNationalDistribuidores",
            position: [2, 12],
            size: [2, 1],
            config: {
                id: "posventa-ranking-nacional",
                type: "rankingIPN",
                indicators: [
                    {
                        indicator: "POSVENTA_SATISFACCION",
                        keyExtract: ["group", "position", "groupCount"],
                        grouped: "geoLocation",
                        label: "group",
                        value: "position",
                        singleton: true,
                        sort: { value: "desc" },
                        limit: 1,
                        extras: {
                            aggregation: "rankingPosition",
                            rankingPositionScope: 100,
                            criticalMomentId: getCmID("servicio"),
                        },
                    },
                ],
            },
        },
        {
            title: "widgets.otherIndicators",
            type: "label",
            position: [0, 13],
            size: [4, 1],
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.paymentPostventa',
            position: [0, 14],
            size: [1, 2],
            indicator: 'POSVENTA_FACTURACION',
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.budgetPostventa',
            position: [1, 14],
            size: [1, 2],
            indicator: 'POSVENTA_PRESUPUESTO',
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.repairPostventa',
            position: [2, 14],
            size: [1, 2],
            indicator: 'POSVENTA_REPARACION_VEHICULO',
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.repairAvailablePostventa',
            position: [3, 14],
            size: [1, 2],
            indicator: 'POSVENTA_DISPONIBILIDAD_TALLER',
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.explicacionTrabajoPostventa',
            position: [0, 16],
            size: [1, 2],
            indicator: 'POSVENTA_EXPLICACION_TRABAJO',
            criticalMomentId: getCmID("servicio"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'mapByGeos',
            title: 'widgets.geoLocationMap',
            position: [1, 16],
            size: [2, 2],
            criticalMomentId: getCmID("venta"),
            indicator: 'VENTA_NPS',
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCountWithLinks',
            title: 'Nube de Palabras / Frecuencia en comentarios',
            position: [0, 18],
            size: [4, 2],
            criticalMomentId: getCmID("servicio"),
        }),
    ],
} as DasboardConfig

import { widgetsLibrary } from '../widgetsLibrary/widgetsLibrary'


export const GREEN_1 = '#87dd76'
export const GREEN_2 = '#AEDD76'
export const ORANGE = '#F3AF5A'
export const BLUE = '#8CF0EA'
export const RED_2 = '#DB7476'
export const RED_1 = '#db7476'
export const YELLOW = '#F1E713'

export const getCmID = (code: string): number => {
  if (code === 'venta') return 10651
  else if (code === 'servicio' || code === 'postventa') return 10652
  else return 1
}

export const CJ_IAMSA_ORDER = {
  'Compra': 1,
  'Abordaje': 2,
  'Autobús-Comodidad': 3,
  'Autobús-Funcionalidad': 4,
  'Autobús-Limpieza': 5,
  'Conductor': 6,
  'Arribo': 7
}

export const iamsaMcgExperienceMapper = (data: any) => {
  //orden solicitado por el cliente
  const order = {
    'Compra': 0,
    'Abordaje': 1,
    'Conductor': 2,
    'Autobús-Comodidad': 3,
    'Autobús-Limpieza': 4,
    'Autobús-Funcionalidad': 5,
    'Arribo': 6,
  }

  const dataCopy = [...data]
  dataCopy.sort((a: any, b: any) => b.value - a.value)

  const result: any[] = []

  dataCopy.forEach((item) => {
    result.push(item)
  })

  return result

}

export const commonWidgets: CommonWidget = {
  ...widgetsLibrary,
}

export const IAMSA_SCALE_3_COLORS = {
  'promoters': GREEN_1,
  'passives': ORANGE,
  'detractors': RED_2,
  'positive': GREEN_1,
  'neutral': ORANGE,
  'negative': RED_1,
  1: GREEN_1,
  2: ORANGE,
  3: RED_1,
}

import { getCommonWidget } from "../../dashWidgetHelper";
import { PRODUTURO_SCALE_5_COLORS, PRODUTURO_PIE_COLORS, PRODUTURO_PIE_SINO_COLORS, commonWidgets, getProfuturoAtencion, getProfuturoNpsAcumulado, getProfuturoNpsAcumuladoFourMonth, getProfuturoNpsPorMes, getExtrasFechas, getMapperBimestralHistorico, getProfuturoNpsAcumuladoByBimonthly } from "../commonWidgetsProfuturo";


export default {
    title: 'Acompañamiento Dashboard',
    description: 'Acompañamiento Dashboard',
    backgroundClassName: (filters: FiltersUI) => 'epsilon-hero-bg',
    widgets: (filters: FiltersUI) => [
        {
            title: 'Principales Indicadores',
            position: [0, 0],
            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },

        getProfuturoNpsAcumuladoByBimonthly({
            size: [1, 2],
            indicator: 'cuest_pens_ley_pregunta_2',
            extras: getExtrasFechas
        }),
        
        getProfuturoNpsPorMes({
            helpText: 'A partir de tu experiencia, en una escala del 0 al 10, dónde 0 es nada probable y 10 muy probable, ¿qué tan probable es que nos recomiendes con un amigo o familiar?',
            size: [1, 2],
            indicator: 'cuest_pens_ley_pregunta_2',
            extras: getExtrasFechas,
            historicGrouped: 'formattedBimonthlyPeriod',
            historicLabel: 'group',
            historicExtras: getExtrasFechas,
            historicMapper: getMapperBimestralHistorico
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Ultimo motivo de contacto',
            size: [2, 2],
            helpText: 'Cuál fue el último motivo por el que nos contactaste?',
            indicator: 'cuest_pens_ley_pregunta_35',
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Medio de Contacto',
            size: [2, 2],
            helpText: '¿Por cuál medio te gustaría recibir información sobre tu pensión?',
            indicator: 'cuest_pens_ley_pregunta_38',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Pago no puntual',
            size: [2, 2],
            helpText: 'Mencionaste que tu pago no fue puntual, por favor indícanos cuál fue:',
            indicator: 'cuest_pens_ley_pregunta_32',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Elección de un préstamo, Clientes CON PRÉSTAMO',
            size: [4, 2],
            helpText: '¿Cuál es el motivo principal por el que accediste a sacar un préstamo en Profuturo?',
            indicator: 'cuest_pens_ley_pregunta_11',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Razón clave de elegir pensionarte con Profuturo, Cliente SIN PRÉSTAMO',
            size: [4, 2],
            helpText: '¿Cuál es el principal motivo por el que elegiste tu pensión con Profuturo?',
            indicator: 'cuest_pens_ley_pregunta_9',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Recomendación',
            size: [2, 2],
            helpText: 'En el último año, ¿recomendaste a Profuturo a otras personas?',
            indicator: 'cuest_pens_ley_pregunta_22',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS
            },
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Cantidad de personas recomendadas',
            size: [2, 2],
            helpText: '¿A cuántas personas le recomendaste Profuturo?',
            indicator: 'cuest_pens_ley_pregunta_23',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Obtención de Préstamo, Clientes SIN PRÉSTAMO',
            size: [2, 2],
            helpText: 'Si tu experiencia con Profuturo se mantiene igual que hasta ahora, ¿estarías dispuesto a contratar un préstamo con nosotros?',
            indicator: 'cuest_pens_ley_pregunta_24',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Obtención de Préstamo, Clientes CON PRÉSTAMO',
            size: [2, 2],
            helpText: 'Si tu experiencia con Profuturo se mantiene igual que hasta ahora ¿estarías dispuesto a contratar nuevamente un préstamo con nosotros?',
            indicator: 'cuest_pens_ley_pregunta_28',
            extraConfigs: {
                colors: PRODUTURO_PIE_SINO_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCount',
            title: 'Motivo por el cual aceptaría/vovería a solicitar un préstamo',
            size: [2, 2],
            helpText: 'Razón de contratar un préstamo',
            indicator: 'cuest_pens_ley_pregunta_31',
            extras: getExtrasFechas,
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Motivo por el que no aceptaría un préstamo',
            size: [2, 2],
            helpText: '¿Cuál es la razón principal por la cual no contratarías un préstamo con Profuturo?',
            indicator: 'cuest_pens_ley_pregunta_26',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: 'Medio de preferecia',
            size: [2, 2],
            helpText: '¿Por cuál medio te gustaría recibir información sobre tu pensión?',
            indicator: 'cuest_pens_ley_pregunta_38',
            extraConfigs: {
                colors: PRODUTURO_PIE_COLORS,
            },
            extras: getExtrasFechas,
        }),

        {
            title: 'Atributos en la experiencia de Grado de SATISFACION',

            size: [4, 1],
            type: 'label',
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La facilidad para comunicarse con Profuturo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_14',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La rapidez y facilidad en los procesos / trámites',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_15',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La buena atención por parte del personal',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_16',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La recepción de mi Estado de Cuenta de manera puntual (mensualmente) Clientes con Préstamo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_17',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'El cumplimiento con lo que me ofrecieron al momento de la contratación. Clientes con Préstamo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_18',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La puntualidad en el pago de mi pensión.',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_19',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La comunicación recibida por parte del Profuturo',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_20',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'stackedBar',
            title: 'La frecuencia con la que me contactan',
            size: [4, 1],
            helpText: '¿Qué tan satisfecho estás con cada uno de los siguientes aspectos?',
            indicator: 'cuest_pens_ley_pregunta_21',
            extraConfigs: {
                colors: PRODUTURO_SCALE_5_COLORS
            },
            extras: getExtrasFechas,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'Análisis de Sentimientos',
            position: [0, 3],
            size: [4, 4],
            indicator: 'SENTIMENT',
            extras: getExtrasFechas
        }),

    ]
} as DasboardConfig
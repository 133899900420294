import { COLORS_CONFIG_BAR } from "@components/widgets/apacheEchart/Commons"
import { answeredMapper, invalidMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar } from "../widgetsLibrary/widgetsLine"
import { commonWidgets, getCmID, suzukiCustomPieMapper, suzukiCustomerJourneyMapper, SUZUKI_SCALE_3_COLORS } from "./commonWidgetsSuzuki"

export default {
    title: "Nacional Venta Dashboard",
    description: "Dashboard de Nacional Venta",
    backgroundClassName: (filters: any) => {
        return 'epsilon-hero-bg'
    },
    widgets: (filters: FiltersUI) => [
        getLineDetailedRadar({
            mainIndicator: 'VENTA_SATISFACCION',
            radialIndicators: [
                'VENTA_SATISFACCION',
                'VENTA_RECOMENDACION',
                'VENTA_FINANCIAMIENTO',
                'VENTA_ENTREGA',
                'VENTA_EJECUTIVO'
            ],
            useNormalizedRadial: false,
            radialType: 'byIndicator',
            position: [0, 0],
            size: [4, 3],
            title: 'Satisfacción General',
            groupByLevel: 2,
            extraConfigs: {
                backgroundColor: 'transparent',
                theme: 'dark',
                showRadialChart: true,
                initialVisibleChart: 1,
                maxValue: 10,
            },
            criticalMomentInclude: [getCmID("venta")],
            indicatorsNames: {
                'VENTA_SATISFACCION': 'Satisfacción',
                'VENTA_FINANCIAMIENTO': 'Financiamiento',
                'VENTA_EJECUTIVO': 'Venta Consultiva',
                'VENTA_RECOMENDACION': 'NPS',
                'VENTA_ENTREGA': 'Entrega',
            },
        }),
        {
            title: 'Encuestas',
            type: 'label',
            position: [0, 3],
            size: [2, 1],
            config: { color: '#B7AADC' },
        },
        {
            title: 'Alertas',
            type: 'label',
            position: [2, 3],
            size: [2, 1],
            config: { color: '#B7AADC' },
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'surveyCountTrendByMonth',
            position: [0, 4],
            size: [1, 2],
            indicator: 'SURVEY_COUNT',
            criticalMomentInclude: [getCmID("venta")],
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'surveyCountTrendByWeek',
            position: [1, 4],
            size: [1, 2],
            indicator: 'SURVEY_COUNT',
            criticalMomentInclude: [getCmID("venta")],
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'alertsTrendByMonth',
            subTitle: 'widgets.alertsCountMonth',
            position: [2, 4],
            size: [1, 2],
            indicator: 'ALERT_FIRST_RESPONSE_TIME',
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'alertsTrendByWeek',
            subTitle: 'widgets.alertsCountWeek',
            position: [3, 4],
            size: [1, 2],
            indicator: 'alert-first-response-time',
            criticalMomentId: getCmID("venta"),
        }),

        {
            title: 'Análisis de sentimientos',
            type: 'label',
            position: [0, 6],
            size: [4, 1]
        },

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'textAnalysis',
            title: 'widgets.sentimentAnalysis',
            position: [0, 7],
            size: [4, 4],
            indicator: 'SENTIMENT',
            criticalMomentId: getCmID("venta"),
        }),

        {
            title: 'Sucursales',
            type: 'label',
            position: [0, 11],
            size: [4, 1],
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'mapByGeos',
            title: 'widgets.geoLocationMap',
            position: [0, 12],
            size: [2, 2],
            criticalMomentId: getCmID("venta"),
            indicator: 'VENTA_NPS',
        }),

        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByGeoBest',
            title: 'widgets.bestGeoLocationsVenta',
            position: [2, 12],
            size: [1, 2],
            extraConfigs: {
                backgroundColor: '#501C78',
                titleBackgroundColor: '#88DD76',
                fontSize: '18px',
                //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
                limit: 3
            },
            indicator: 'VENTA_SATISFACCION',
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByGeoWorst',
            title: 'widgets.worstGeoLocationsVenta',
            position: [3, 12],
            size: [1, 2],
            extraConfigs: {
                backgroundColor: '#501C78',
                titleBackgroundColor: '#db7476',
                fontSize: '18px',
                //calculatedLimit: (data: any) => Math.min(3, (data.length) / 2),
                limit: 3
            },
            indicator: 'VENTA_SATISFACCION',
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByQuestionBest',
            title: 'widgets.bestRotativasVenta',
            position: [0, 14],
            size: [2, 2],
            extraConfigs: { fontSize: '22px' },
            indicator: 'VENTA_ROTATIVAS',
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByQuestionWorst',
            title: 'widgets.worstRotativasVenta',
            position: [2, 14],
            size: [2, 2],
            extraConfigs: { fontSize: '22px' },
            indicator: 'VENTA_ROTATIVAS',
            criticalMomentId: getCmID("venta"),
        }),


        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByTagBest',
            title: 'widgets.bestTagsVenta',
            position: [0, 16],
            size: [2, 1],
            indicator: 'SENTIMENT',
            criticalMomentId: getCmID("venta"),
            extras: {
                resultType: 'top',
                trunc: "HALF_DOWN",
                topSize: 3
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'rankingByTagWorst',
            title: 'widgets.worstTagsVenta',
            position: [2, 16],
            size: [2, 1],
            indicator: 'SENTIMENT',
            criticalMomentId: getCmID("venta"),
            extras: {
                resultType: 'bottom',
                trunc: 'HALF_UP',
                bottomSize: 3,
            },
        }),

        {
            title: 'Diagrama de Ishikawa',
            type: 'label',
            position: [0, 17],
            size: [4, 1],
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'diagramaIshikawa',
            //title: 'Diagrama Ishikawa',
            position: [0, 18],
            size: [4, 2],
            indicator: 'SENTIMENT',
            criticalMomentInclude: [getCmID("venta")],
        }),

        {
            title: "widgets.otherIndicators",
            type: "label",
            position: [0, 20],
            size: [4, 1],
        },
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.snVenta',
            position: [0, 21],
            size: [1, 2],
            indicator: 'VENTA_SATISFACCION',
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'multipleTrendByMonth',
            title: 'widgets.npsVenta',
            indicator: 'VENTA_NPS',
            showSample: false,
            position: [1, 21],
            size: [1, 2],
            criticalMomentId: getCmID("venta"),
            extraConfigs: {
                colors: SUZUKI_SCALE_3_COLORS
            },
            extras: {
                periods: 6
            }
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGenericByStatus',
            title: 'widgets.calidadDatosVenta',
            position: [2, 21],
            size: [1, 2],
            criticalMomentId: getCmID("venta"),
            indicator: 'calidad-gauge',
            url: 'survey-responses/values',
            key: 'calidad-datos-venta',
            mapper: invalidMapper
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGenericByStatus',
            title: 'widgets.porcRespuestasVenta',
            indicator: 'venta-respuestas-gauge',
            url: 'survey-responses/values',
            key: 'porc-respuestas-venta',
            mapper: answeredMapper,
            position: [3, 21],
            size: [1, 2],
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'multipleGaugeByQuestion',
            title: 'widgets.customerJourneyVenta',
            mapper: suzukiCustomerJourneyMapper,
            indicator: 'VENTA_CUSTOMER_JOURNEY',
            position: [0, 23],
            size: [4, 2],
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'barByGeo',
            title: "widgets.zonalSatisfaction",
            key: 'venta-satisfaccion-zona',
            indicator: "VENTA_SATISFACCION",
            criticalMomentId: getCmID("venta"),
            position: [0, 25],
            size: [4, 2],
            extraConfigs: {
                colors: COLORS_CONFIG_BAR
            },
            extras: {
                groupByLevel: 2,
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'barByGeo',
            title: "widgets.distribuidorSatisfaction",
            key: "venta-satisfaccion-dist",
            indicator: "VENTA_SATISFACCION",
            criticalMomentId: getCmID("venta"),
            position: [0, 27],
            size: [4, 2],
            mapper: (data: any, extra: any) => {
                return data.sort((a: any, b: any) => b.value - a.value).map((item: any) => {
                    return {
                        ...item,
                        label: item.label !== undefined ? item.label.trim().replace('SUZUKI ', '') : undefined,
                        group: item.group !== undefined ? item.group.trim().replace('SUZUKI ', '') : undefined
                    }
                })
            },
            extraConfigs: {
                colors: COLORS_CONFIG_BAR,
                rotateXAxisLegend: true,
                rotateLabel: 90
            },
            extras: {
                groupByLevel: 3,
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: "widgets.driveTestReasonsVenta",
            position: [0, 29],
            size: [2, 2],
            indicator: "VENTA_SIN_PRUEBA_MANEJO_MOTIVO",
            criticalMomentId: getCmID("venta"),
            extraConfigs: {
                center: ['30%', '55%'],
            },
            mapper: suzukiCustomPieMapper,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericMedium',
            title: "widgets.financingReasonsVenta",
            position: [2, 29],
            size: [2, 2],
            indicator: "VENTA_SIN_FINANCIAMIENTO_MOTIVO",
            criticalMomentId: getCmID("venta"),
            extraConfigs: {
                center: ['30%', '55%'],
            },
            mapper: suzukiCustomPieMapper,
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'pieGenericSmall',
            title: "widgets.warrantyExplanation",
            position: [0, 31],
            size: [2, 2],
            indicator: 'VENTA_BENEFICIOS',
            mapper: (data) => {
                return data.map((item) => ({
                    id: item["group"] == null ? "Indefinido" : item["group"],
                    label: item["group"] == null ? "Indefinido" : item["group"],
                    value: item["value"],
                }))
            },
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.entregaVenta',
            position: [2, 31],
            size: [1, 2],
            indicator: 'VENTA_ENTREGA',
            criticalMomentId: getCmID("venta"),
            extraConfigs: {
                maxValue: 5
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.upsellingVenta',
            position: [3, 31],
            size: [1, 2],
            indicator: 'VENTA_ACCESORIOS_ADICIONALES',
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.instalationsVenta',
            position: [0, 33],
            size: [1, 2],
            indicator: 'VENTA_INSTALACIONES',
            criticalMomentId: getCmID("venta"),
            extraConfigs: {
                maxValue: 5
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'gaugeGeneric',
            title: 'widgets.confirmacionEntregaVenta',
            position: [1, 33],
            size: [1, 2],
            indicator: 'VENTA_CONFIRMACION_ENTREGA',
            criticalMomentId: getCmID("venta"),
            extraConfigs: {
                maxValue: 5
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'wordCountWithLinksSinDefaults',
            title: 'Nube de Palabras / Frecuencia en comentarios',
            position: [2, 33],
            size: [2, 2],
            criticalMomentId: getCmID("venta"),
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'barByMonthRegression',
            title: 'Proyección - Satisfacción',
            position: [0, 35],
            size: [2, 2],
            indicator: 'VENTA_SATISFACCION',
            criticalMomentId: getCmID("venta"),
            mapper: (data: any) => {
                return data &&
                    data[0] &&
                    data[0].source &&
                    data[0].source.map((item: number, idx: any) => ({
                        group: item['groupName'],
                        label: item['groupName'],
                        value: data[0].source[idx].value * 10,
                    }))
            },
            extras: {
                aggregation: 'linearRegression'
            },
        }),
        getCommonWidget({
            commonWidgets: commonWidgets,
            name: 'barByQuestion',
            title: 'Peso de atributos sobre Satisfacción',
            position: [2, 35],
            size: [2, 2],
            indicator: 'ATRIBUTOS_SATISFACCION_VENTA',
            criticalMomentId: getCmID("venta"),
            mapper: (data: any) => {
                return [...data].sort((a: any, b: any) => b.value - a.value)
            },
            extraConfigs: {
                wrapTexts: true,
                rotateXAxisLegend: true
            }
        }),
    ],
} as DasboardConfig

import { answeredMapper, invalidMapper } from "../dashHelper"
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, MITSUBISHI_SCALE_3_COLORS } from "./commonWidgetsMitsu"

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  widgets: (filters: FiltersUI) => [
    {
      title: 'labels.venta',
      type: 'label',
      position: [0, 0],
      size: [4, 1],
      //config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [0, 1],
      size: [1, 2],
      indicator: 'VENTA_NPS',
      criticalMomentId: 1,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
      extras: {
        periods: 6
      },
      extraConfigs: {
        useCurrent: 0,
        colors: MITSUBISHI_SCALE_3_COLORS
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snVenta',
      position: [1, 1],
      size: [1, 2],
      indicator: 'VENTA_SATISFACCION',
      criticalMomentId: 1,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
      extraConfigs: {
        maxValue: 10
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 1],
      size: [1, 2],
      url: 'survey-responses/values',
      criticalMomentId: 1,
      mapper: invalidMapper,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: [3, 1],
      size: [1, 2],
      url: 'survey-responses/values',
      criticalMomentId: 1,
      mapper: answeredMapper,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 3]
          : [0, 2]
      },
      //position: [0, 4],
      size: [4, 2],
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 1,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(1))
        )
      },
      extraConfigs: {
        maxValue: 5
      }
    }),
    {
      title: 'labels.postventa',
      type: 'label',
      position: [0, 5],
      size: [4, 1],
      //config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 6]
          : [0, 0]
      },
      size: [1, 2],
      indicator: 'POSVENTA_NPS',
      criticalMomentId: 2,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
      extras: {
        periods: 6,
      },
      extraConfigs: {
        useCurrent: 0,
        colors: MITSUBISHI_SCALE_3_COLORS
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snPostventa',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [1, 6]
          : [1, 0]
      },
      size: [1, 2],
      indicator: 'POSVENTA_SATISFACCION',
      criticalMomentId: 2,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
      extraConfigs: {
        maxValue: 10
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [2, 6]
          : [2, 0]
      },
      size: [1, 2],
      url: 'survey-responses/values',
      criticalMomentId: 2,
      mapper: invalidMapper,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [3, 6]
          : [3, 0]
      },
      size: [1, 2],
      url: 'survey-responses/values',
      criticalMomentId: 2,
      mapper: answeredMapper,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 8]
          : [0, 2]
      },
      //position: [0, 4],
      size: [4, 2],
      indicator: 'POSVENTA_CUSTOMER_JOURNEY',
      criticalMomentId: 2,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(2))
        )
      },
      extraConfigs: {
        maxValue: 5
      }
    }),
    {
      title: 'labels.reparacion',
      type: 'label',
      position: [0, 10],
      size: [4, 1],
      //config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsReparacion',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 11]
          : [0, 0]
      },
      size: [1, 2],
      indicator: 'REPARACION_NPS',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(3))
        )
      },
      extras: {
        periods: 6
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGeneric',
      title: 'widgets.snReparacion',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [1, 11]
          : [1, 0]
      },
      size: [1, 2],
      indicator: 'REPARACION_SATISFACCION',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(3))
        )
      },
      extraConfigs: {
        maxValue: 100
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosReparacion',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [2, 11]
          : [2, 0]
      },
      size: [1, 2],
      url: 'survey-responses/values',
      mapper: invalidMapper,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(3))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasReparacion',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [3, 11]
          : [3, 0]
      },
      size: [1, 2],
      url: 'survey-responses/values',
      mapper: answeredMapper,
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(3))
        )
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyReparacion',
      position: (filters: FiltersUI) => {
        return filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          filters.criticalMoments.includes(0)
          ? [0, 13]
          : [0, 2]
      },
      //position: [0, 4],
      size: [4, 2],
      indicator: 'REPARACION_CUSTOMER_JOURNEY',
      render: (filters: FiltersUI) => {
        return (
          filters !== undefined &&
          filters.criticalMoments !== undefined &&
          filters.criticalMoments !== null &&
          (filters.criticalMoments.includes(0) ||
            filters.criticalMoments.includes(3))
        )
      },
      extraConfigs: {
        maxValue: 100
      },
    })
  ],
} as DasboardConfig

import logoOxxo from '@assets/logos/oxxo-logo.png'
import logoOxxoSmall from '@assets/logos/oxxo-logo-small.png'
import {
    ClientConfigInfo,
    FILTER_TYPES,
    METADADATA_LOAD,
    SurveyResponsesTab,
} from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { omoikaneOxxo } from '../omoikane/omoikaneOxxo'
import {
    getFilterCriticalMomentDefault,
    getFilterCriticalMoments,
} from '../dashboard/dashHelper'
import deafultHelpDefinition from '../help/defaultHelp'


export const oxxoConfigs: ClientConfigInfo = {
    //vocBackendUrl: 'http://localhost:9000/api/',
    lcmBackendUrl: 'https://oxxogas.limetropy.com/api/',
    vocBackendUrl: 'https://oxxo-backend-voc.limetropy.com/api/',
    publicUrl: undefined,
    logoSmall: logoOxxoSmall,
    logo: logoOxxo,
    filtersFromDateSubstract: 1,
    filtersToDateSubstract: 0,
    decimals: 2,
    filters: [
        FILTER_TYPES.DATES,
        FILTER_TYPES.GEOS,
        FILTER_TYPES.CRITICAL_MOMENTS,
    ],
    supportsInternationalization: false,
    msExcelReportExt: 'xlsx',
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    menuDashboards: [
        DASHBOARDS.DEFAULT,
        DASHBOARDS.OXXO_EXT
    ],
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    geosRootId: 0,
    logicalsRootId: 0,
    criticalMomentsRootId: 29,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: 'ESTACION',
        useWorstTags: true
    },
    omoikane: omoikaneOxxo,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS,
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.oxxo,
        default: DASHBOARDS_CONFIGS.oxxo[DASHBOARDS.DEFAULT],
    },
    staticDashboards: {},
    useDashboardDynamicPosition: true,
    //TODO: modificar cuando el backend informe nueva propiedad range
    ratingRanges: {
        defaultRatingMin: 1,
        defaultRatingMax: 10,
    },
    surveyResponses: {
        url: 'survey-responses',
        type: 'post-context',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.IN_PROGRESS],
    },
    alerts: {},
    help: deafultHelpDefinition,
    alertStats: {
        mapGeosPatentChild: {
            13450: [13554],
            9953: [13553],
            13500: [12804],
        }
    }


}

import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const omoikaneOxxo: OmoikaneConfig = {
    decimals: 1,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        OmoikaneTab.CLUSTERS,
    ],
    monthdsCoverage: 2,
    maxTagsNumber: 3,
    indicators: [
        {
            indicator: 'willingness-to-recommend',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'satisfaction',
            keyExtract: [],
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'sentiment',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'survey-count',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
            }
        },
    ],
    sentiment: {
        indicators: { default: 'sentiment' },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            default: [
                { order: 1, name: 'NPS', key: 'willingness-to-recommend', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction', widgetType: 'gauge' },
            ]
        }
    },
    clusters: {
        default: {
            counters: { indicator: 'survey-count' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'satisfaction',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'willingness-to-recommend',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'sentiment',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'sentiment',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            limit: 100
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'survey-count',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    }/*,
                {
                    id: 'alerts-counters',
                    indicator: 'alerts-counters',
                    url: 'survey-responses/counters',
                    keyExtract: [],
                    grouped: 'mlCluster',
                    extras: {
                        useGet: true
                    }
                }*/,
                    {
                        id: 'alerts-counters',
                        indicator: 'alert_counters',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {}
                    }
                ]
            }
        }
    },
    customerJourney: {
        default: (data: any) => {
            const nps = data['willingness-to-recommend']?.data[0].value
            const csat = data['satisfaction']?.data[0].value

            return [
                'Saludo/Bienvenida',
                'Levantamiento del pedido',
                'Carga',
                'Cobro',
                'Despedida/Cierre'
            ].map((item: string, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item,
                items: [
                    { order: 1, name: 'NPS', value: nps },
                    { order: 2, name: 'CSAT', value: csat },
                ]
            }))
        }
    }
}
